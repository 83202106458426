import $axios from '@/plugins/axios'

class QuestionnaireService {

    get() {

        return $axios.get('/subsections')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(id) {

        return $axios.get(`/subsections/${id}`)
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    
    getCategories(id) {
        
        return $axios.get(`/subsections/${id}/categories`)
        .then(res => {
            
            return res.data;
        })
        .catch((error) => {
            
            console.log(error)
            throw error;
        })
    }
    
    create(data) {

        return $axios.post(`/subsections`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(id, data) {

        return $axios.put(`/subsections/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    delete(id) {

        return $axios.delete(`/subsections/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new QuestionnaireService();
