<template>
    <div class="main-container">
        <div v-if="page">
            <div class="teachers-section-1">
                <div class="teachers-section-1-title">
                    <h1>{{ page.title }}</h1>
                </div>
                <p class="mb-0 teachers-p-text" style="white-space: pre-wrap; line-height: 32px;" v-html="page.text">
                </p>
            </div>
            <div class="teachers-section-2">
                <v-row>
                    <v-col class="fourth-cols" id="fourth-col-1"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-2"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-3"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-4"></v-col>
                </v-row>
            </div>

            <div class="teachers-section-3">
                <v-row>
                    <v-col cols="2" class="section-3-cols" v-for="(file, index) in page.files" :key="index">
                        <v-img :src="file.pivot.file_image_url" max-width="82"/>
                        <p class="section-3-cols-title">{{ file.pivot.file_title }}</p>
                        <p class="section-3-cols-sub-title">{{ file.pivot.file_text }}</p>
                        <v-btn @click="openPdfPage(file)">Отвори PDF</v-btn>
                    </v-col>
                </v-row>
              <v-row>
                <v-col cols="3" v-for="(video_link, index) in page.video_links" :key="index">
                  <iframe width="100%" height="300" :src="video_link + '?rel=0'">
                  </iframe>
                </v-col>
              </v-row>
            </div>
        </div>

        <div class="footer-container">
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>

import PageService from '@/services/page.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
    name: 'GeneralPage',

    components: {
        footerComponent,
    },

    data: function() {
        return {
            page: null,
        };
    },

    watch: {
        '$route.params.slug': function(slug) {
            this.loadPage(slug);
        },
    },

    methods: {
        openPdfPage(file){
            
            console.log(file)
            this.$router.push({name: 'pdf-page', params: {id: file.id}})
        },
        loadPage(slug) {
            PageService.getOne(slug).then((res) => {
                this.page = res;
            });
        },
        goToNewRoute(link) {
            this.$router.push(link);
        },
    },

    created() {

        if (this.$route.params.slug) {
            this.loadPage(this.$route.params.slug);
        }
    },

};
</script>

<style lang="scss" scoped>

.teachers-section-3 {
    margin-bottom: 100px;
    margin-top: 100px;
}

.section-3-cols {
    margin: 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);

    .v-btn {
        background-color: #5DA2D5 !important;
        color: white;
    }

}

.section-3-cols-title {
    text-shadow: none;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: Inter;
}

.section-3-cols-sub-title {
    text-shadow: none;
    font-size: 0.9rem;
    font-family: Inter;
    color: darkgrey;
}

.teachers-section-1-title {
    text-align: left;
    margin-left: 200px;
    color: #5DA2D5;
    padding-top: 50px;
    text-shadow: none;
    font-family: Inter;
    font-weight: bold;
}

.teachers-section-1 {

    background: linear-gradient(0deg, rgba(206, 204, 204, 0.2), rgba(206, 204, 204, 0.2)), url(../../assets/photo-background.webp);

    p {
        text-shadow: none;
        padding-top: 40px;
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 200px;
        font-size: 1.5rem;
        font-family: Inter;
        line-height: 2.8rem;
        text-align: left;
    }
}


.teachers-section-2 {

    margin-bottom: 30px;

    .fourth-cols {
        width: 100%;
        height: 40px !important;
    }

    #fourth-col-1 {
        background-color: #F68887;
    }

    #fourth-col-2 {
        background-color: #F3D24F;
    }

    #fourth-col-3 {
        background-color: #90CCF4;
    }

    #fourth-col-4 {
        background-color: #5DA2D5;
    }

}

</style>
