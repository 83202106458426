import $axios from '@/plugins/axios'

class QuestionService {

    get() {

        return $axios.get('/questions')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(id) {

        return $axios.get(`/questions/${id}`)
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    
    getAnswers(id) {
        
        return $axios.get(`/questions/${id}/answers`)
        .then(res => {
            
            return res.data;
        })
        .catch((error) => {
            
            console.log(error)
            throw error;
        })
    }
    
    create(data) {

        return $axios.post(`/questions`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(id, data) {

        return $axios.post(`/questions/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    reorder(data) {

        return $axios.post(`/reorder-questions`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    delete(id) {

        return $axios.delete(`/questions/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new QuestionService();
