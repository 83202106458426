<template>
  <div class="main-container">
    <div class="colorP" v-if="colorPickerOpened">
      <v-color-picker
        dot-size="25"
        swatches-max-height="200"
        v-model="drawingColor"
      ></v-color-picker>
      <v-btn color="primary" @click="closeColorPicker()">Затвори</v-btn>
    </div>
    <canvas id="canvas" @mousedown="startPainting" @mouseup="finishedPainting" @mousemove="draw"
            :class="{ 'canvas-inactive': !areWeDrawing}"></canvas>
    <v-container fluid style="margin-bottom: 20px;" class="main-cont">
      <v-row>
        <v-col
          cols="2"
          md="3">
          <div class="category-box" v-for="(category, index) in categories"
               :style="{backgroundColor: category.color}"
               :key="index + '_category'"
               :class="{'make-bigger': category.subsection.challenge_id === challenge.id}"></div>
        </v-col>
        <v-col
          cols="3"
          md="3"
        >
          <div
            class="pa-2 white rounded-circle d-inline-block challenge-action"
            @click="openColorPicker()">
            <div>
              <v-img
                max-width="35"
                src="@/assets/brush.png"></v-img>
            </div>
          </div>
          <div
            class="pa-2 white rounded-circle d-inline-block challenge-action"
            @click="startDrawing()">
            <div>
              <v-img v-if="!areWeDrawing"
                     max-width="35"
                     src="@/assets/pencill.png"></v-img>

              <v-img v-if="areWeDrawing"
                     max-width="35"
                     src="@/assets/closebtn.png"></v-img>
            </div>
          </div>
          <div
            class="pa-2 white rounded-circle d-inline-block challenge-action"
            @click="openDictionaryForm()">
            <div>
              <v-img
                max-width="35"
                src="@/assets/dictionary.png"></v-img>
            </div>
          </div>
          <div
            v-if="currentCategory && currentCategory.subsection.challenge_id === 1"
            class="pa-2 white rounded-circle d-inline-block challenge-action"
            @click="openVideoForm()">
            <div>
              <v-img
                max-width="35"
                src="@/assets/video.png"></v-img>
            </div>
          </div>
        </v-col>
        <v-col
          cols="6"
          md="6"
        >
          <div class="question-answer-box"
               v-for="(result, index) in questionResults"
               :key="index + '_question'"
               :style="{backgroundColor: result.has_answered_correctly != null? (result.has_answered_correctly ?'#649566': '#E43939'): '#EBEBEB'}"></div>
          <div class="question-count-box"><p>{{
              currentQuestionIndex < questionResults.length ?
                currentQuestionIndex + 1 :
                questionResults.length
            }} от {{ questionResults.length }}</p>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="1"
          md="1">
          <div class="subsection-box"
               v-for="(subsection, index) in all_subsections"
               :key="index + '_question'"
               :style="{backgroundColor: subsection.color}"
               @click="goToOtherChallenge(subsection.challenge_id)"><p>{{ index + 1 }}</p></div>
        </v-col>
        <v-col v-if="currentQuestion"
               cols="4"
               md="4">
          <div class="question-text-container" style="white-space: pre-wrap;" v-html="currentQuestionText">
          </div>
        </v-col>
        <v-col
          cols="7"
          md="7">
          <div class="question-container" v-if="currentQuestion">
            <v-row>
              <v-col>
                <div class="question-image" v-if="currentQuestion.images.length > 0">
                  <img class="mx-auto question-img" :src="currentQuestion.images[0].url"/>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="question-question">
                  <v-row>
                    <v-col cols="1">
                      <div class="question-type-box">
                        <v-icon v-if="currentQuestion.type_id == 1">mdi-check</v-icon>
                        <v-icon v-if="currentQuestion.type_id == 2">mdi-braille</v-icon>
                        <p v-if="currentQuestion.type_id == 3">T</p>
                        <v-icon v-if="currentQuestion.type_id == 4">mdi-arrow-expand</v-icon>
                      </div>
                    </v-col>
                    <v-col cols="11" v-html="currentQuestion.question">
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row v-if="currentQuestion.type_id === 1 || currentQuestion.type_id === 4">
              <v-col v-html="currentQuestion.side_text">
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div class="question-answers">
                  <v-row v-if="currentQuestion.type_id == 1">
                    <v-col cols="6" v-for="(answer, index) in currentQuestion.answers"
                           :key="index + '_answer'">
                      <v-checkbox color="success" v-model="answer.selected" :label="answer.text"
                                  class="questions-check-boxes shrink"
                                  @change="selectAnswer($event, answer)"></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentQuestion.type_id == 2">
                    <v-col cols="12">
                      <p>
                                                <span v-for="(text, index) in currentQuestion.textArray"
                                                      :key="index + '_text'"
                                                      style="white-space: pre-wrap;">
                                                  {{ text }} <v-text-field class="input-text-type-2"
                                                                           @drop="drop($event, index)"
                                                                           @dragover="allowDrop($event)"
                                                                           @input="addText($event, index)"
                                                                           v-if="index < currentQuestion.textArray.length - 1"
                                                                           :id="Math.ceil(Math.random()*100) + '_drag_drop_input'"
                                                                           type="text"/>
                                                </span>
                      </p>
                    </v-col>

                    <v-col cols="6" v-for="(answer, index) in currentQuestion.answers"
                           :key="index + '_answer'">
                      <div>

                        <button
                          class="drag-and-drop-btn"
                          draggable="true"
                          @dragstart="drag($event)"
                          :value="answer.text">{{ answer.text }}
                        </button>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentQuestion.type_id == 3">
                    <v-col>
                      <p>
                                                <span v-for="(text, index) in currentQuestion.textArray"
                                                      :key="index + Math.floor(Math.random() * 10000) + '_text'"
                                                      style="white-space: pre-wrap;">
                                                  {{ text }} <v-text-field class="input-text-type-3"
                                                                           @input="addText($event, index)"
                                                                           v-if="index < currentQuestion.textArray.length - 1"
                                                                           :value="''"
                                                                           type="text"/>
                                                </span>
                      </p>
                    </v-col>
                  </v-row>
                  <v-row v-if="currentQuestion.type_id == 4">
                    <v-col cols="6">
                      <v-row v-for="(answer, index) in currentQuestion.answers"
                             :key="index + '_answer'">
                        <v-col cols="12" v-if="!answer.position">

                          <div class="connect-answers-box" :id="answer.id + '_answer_box'"
                               @click="selectConnectingAnswer(answer)">
                            {{ answer.text }}
                          </div>

                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="6">
                      <v-row v-for="(answer, index) in currentQuestion.answers"
                             :key="index + '_answer'">
                        <v-col cols="12" v-if="answer.position">

                          <div class="connect-answers-box" :id="answer.id + '_answer_box'"
                               @click="selectConnectingAnswer(answer)">
                            {{ answer.text }}
                          </div>

                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <div style="text-align: center;">
                  <v-btn v-if="showNextQuestionButton" @click="nextQuestion">Следващ</v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <div v-if="hasEnded">
            <v-row>
              <v-col>
                <p>Край</p>
              </v-col>
            </v-row>
          </div>
        </v-col>
      </v-row>
    </v-container>
    <div class="footer-container">
      <footerComponent></footerComponent>
    </div>
    <div class="dictionary-container">
      <dictionary-modal :value="showDialog" @closeDictionary="closeModal()"></dictionary-modal>
    </div>


    <div class="video-container">
      <video-modal v-if="showDialogVideo" v-model="showDialogVideo" :videoLink="videoLink" @closeVideo="closeModalVideo()"></video-modal>
    </div>
  </div>
</template>

<script>

import CategoryService from '@/services/category.service';
import SubsectionService from '@/services/subsection.service';
import ChallengeService from '@/services/challenge.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');
const dictionaryModal = () => import('../../components/Common/DictionaryModal.vue');
const videoModal = () => import('../../components/Common/VideoModal.vue');

export default {
  name: 'ChallengePage',

  components: {
    footerComponent,
    dictionaryModal,
    videoModal,
  },

  data: function() {
    return {
      videoLink: '',
      videoModalKey: 0,
      areWeDrawing: false,
      drawingColor: '#000',
      colorPickerOpened: false,
      challenge: [],
      all_subsections: [],
      showDialog: false,
      showDialogVideo: false,
      canvas: null,
      ctx: null,
      currentCategory: null,
      painting: false,
      categories: [],
      questions: [],
      currentSelectedAnswerToConnect: null,
      currentQuestionIndex: 0,
      currentQuestion: null,
      questionResults: [],
      showNextQuestionButton: true,
      allPossibleColorsForLinkingAnswers: [
        '#4ac235',
        '#e088f4',
        '#a3784f',
        '#c98b31',
        '#a33162',
        '#1f1e6c',
        '#fc2c03',
        '#f0fc03',
        '#5e4a59',
      ],
      hasEnded: false,
    };
  },
  computed: {
    currentQuestionText(){
      return this.currentQuestion.text;
    }
  },
  methods: {

    allowDrop(event) {
      event.preventDefault();
    },

    drop(event, index) {
      console.log(event);
      event.target.value = event.dataTransfer.getData('text');
      this.currentQuestion.answersInput[index] = event.dataTransfer.getData('text');
      // // this.setText(event.target.value);
      // this.paragraph = event.target.value;
    },

    drag(event) {
      console.log(event.target.value);
      event.dataTransfer.setData('text', event.target.value);
    },

    closeModal(value) {
      if (!value) {
        this.showDialog = false;
      }
    },

    closeModalVideo(value) {
      if (!value) {
        this.showDialogVideo = false;
      }
    },

    openVideoForm() {
      this.showDialogVideo = true;
    },

    openDictionaryForm() {
      this.showDialog = true;
    },

    openColorPicker() {

      this.colorPickerOpened = true;
    },

    closeColorPicker() {
      this.colorPickerOpened = false;

    },

    draw(e) {
      if (!this.painting) {
        return;
      }

      this.ctx.lineWidth = 7;
      this.ctx.lineCap = 'round';

      this.ctx.strokeStyle = this.drawingColor;

      this.ctx.lineTo(e.pageX - this.canvas.offsetLeft, e.pageY - this.canvas.offsetTop);
      this.ctx.stroke();

      this.ctx.beginPath();
      this.ctx.moveTo(e.pageX - this.canvas.offsetLeft, e.pageY - this.canvas.offsetTop);
    },

    startPainting(e) {
      if (this.canvas) {
        this.painting = true;
        this.draw(e);
      }
    },

    finishedPainting() {
      if (this.canvas) {
        this.painting = false;
        this.ctx.beginPath();
      }
    },

    goToOtherChallenge(challengeId) {

      let challengeLink = '/challenges/' + challengeId;

      this.$router.push(challengeLink);
    },

    addText($event, index) {

      this.currentQuestion.answersInput[index] = $event.trim().toLowerCase();
    },

    selectConnectingAnswer(answer) {

      if (!answer.position && !answer.isSelectedInFrontEnd) {
        this.currentSelectedAnswerToConnect = answer;

        document.getElementById(
          this.currentSelectedAnswerToConnect.id + '_answer_box').style.backgroundColor = '#5DA2D5';
        document.getElementById(this.currentSelectedAnswerToConnect.id + '_answer_box').style.color = 'white';

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          if (!this.currentQuestion.answers[i].isSelectedInFrontEnd &&
            !this.currentQuestion.answers[i].position && this.currentQuestion.answers[i].id !== answer.id) {

            document.getElementById(
              this.currentQuestion.answers[i].id + '_answer_box').style.backgroundColor = 'white';
            document.getElementById(
              this.currentQuestion.answers[i].id + '_answer_box').style.color = 'black';
          }
        }

      }

      if (!answer.position && answer.isSelectedInFrontEnd) {
        document.getElementById(answer.id + '_answer_box').style.backgroundColor = 'white';
        document.getElementById(answer.id + '_answer_box').style.color = 'black';

        document.getElementById(answer.answer_linked + '_answer_box').style.backgroundColor = 'white';
        document.getElementById(answer.answer_linked + '_answer_box').style.color = 'black';

        if (this.currentSelectedAnswerToConnect) {
          if (this.currentSelectedAnswerToConnect.id === answer.id) {
            this.currentSelectedAnswerToConnect = null;
          }
        }

        let linkedAnswer = answer.answer_linked;

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {

          if (this.currentQuestion.answers[i].id === answer.id || this.currentQuestion.answers[i].id ===
            linkedAnswer) {
            this.currentQuestion.answers[i].answer_linked = null;
            this.currentQuestion.answers[i].isSelectedInFrontEnd = false;
          }
        }

      }

      if (answer.position && !this.currentSelectedAnswerToConnect && answer.isSelectedInFrontEnd) {

        document.getElementById(answer.id + '_answer_box').style.backgroundColor = 'white';
        document.getElementById(answer.id + '_answer_box').style.color = 'black';

        document.getElementById(answer.answer_linked + '_answer_box').style.backgroundColor = 'white';
        document.getElementById(answer.answer_linked + '_answer_box').style.color = 'black';

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          if (this.currentQuestion.answers[i].id === answer.id || answer.answer_linked ===
            this.currentQuestion.answers[i].id) {

            this.currentQuestion.answers[i].isSelectedInFrontEnd = false;

          }
        }
      }

      if (answer.position && this.currentSelectedAnswerToConnect) {

        let backgroundColor = this.getRandomColor();

        document.getElementById(
          this.currentSelectedAnswerToConnect.id + '_answer_box').style.backgroundColor = backgroundColor;
        document.getElementById(this.currentSelectedAnswerToConnect.id + '_answer_box').style.color = 'white';

        document.getElementById(answer.id + '_answer_box').style.backgroundColor = backgroundColor;
        document.getElementById(answer.id + '_answer_box').style.color = 'white';

        if (answer.isSelectedInFrontEnd) {

          document.getElementById(answer.answer_linked + '_answer_box').style.backgroundColor = 'white';
          document.getElementById(answer.answer_linked + '_answer_box').style.color = 'black';

          for (let i = 0; i < this.currentQuestion.answers.length; i++) {

            if (this.currentQuestion.answers[i].id === answer.answer_linked) {
              this.currentQuestion.answers[i].answer_linked = null;
              this.currentQuestion.answers[i].isSelectedInFrontEnd = false;
            }
          }
        }

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {
          if (this.currentQuestion.answers[i].id === answer.id || this.currentSelectedAnswerToConnect.id ===
            this.currentQuestion.answers[i].id) {

            this.currentQuestion.answers[i].isSelectedInFrontEnd = true;

            if (this.currentQuestion.answers[i].id === answer.id) {
              this.currentQuestion.answers[i].answer_linked = this.currentSelectedAnswerToConnect.id;
            }

            if (this.currentQuestion.answers[i].id === this.currentSelectedAnswerToConnect.id) {
              this.currentQuestion.answers[i].answer_linked = answer.id;
            }
          }
        }

        this.currentSelectedAnswerToConnect = null;
      }
    },

    getRandomColor() {

      let rndmColorIndx = Math.floor(Math.random() * this.allPossibleColorsForLinkingAnswers.length);

      let randomColor = this.allPossibleColorsForLinkingAnswers[rndmColorIndx];

      if (this.allPossibleColorsForLinkingAnswers.length === 1) {

        this.allPossibleColorsForLinkingAnswers = [
          '#4ac235',
          '#e088f4',
          '#a3784f',
          '#c98b31',
          '#a33162',
          '#1f1e6c',
          '#fc2c03',
          '#f0fc03',
          '#5e4a59',
        ];

      } else {
        this.allPossibleColorsForLinkingAnswers.splice(rndmColorIndx, 1);
      }

      return randomColor;
    },

    getChallenge(id) {
      ChallengeService.getOne(id).then((res) => {
        this.challenge = res;
      });
    },
    getChallengeSubsections(id) {
      ChallengeService.getOne(id).then((res) => {
        this.challenge = res;
      });
    },
    getAllSubsections() {
      SubsectionService.get().then((res) => {
        this.all_subsections = res;
      });
    },
    getCategories() {
      this.categories = [
        {
          id: 1,
          color: '#abdb77',
          subsection:
            {
              challenge_id: 1,
            },
        },
        {
          id: 2,
          color: '#90ccf4',
          subsection:
            {
              challenge_id: 2,
            },
        },
        {
          id: 3,
          color: '#f3d24f',
          subsection:
            {
              challenge_id: 3,
            },
        },
        {
          id: 4,
          color: '#f68987',
          subsection:
            {
              challenge_id: 4,
            },
        },
      ];
    },
    getQuestions(id) {
      CategoryService.getQuestions(id).then((res) => {
        this.questions = res;

        for (let i = 0; i < this.questions.length; i++) {

          if (this.questions[i].type_id === 4) {

            for (let o = 0; o < this.questions[i].answers.length; o++) {
              this.questions[i].answers[o]['isSelectedInFrontEnd'] = false;
              this.questions[i].answers[o]['answer_linked'] = null;
            }
          }

          if (this.questions[i].type_id === 3 || this.questions[i].type_id === 2) {

            let inputQuestionTextArray = this.questions[i].side_text.split(/\{.*?\}/g);

            // inputQuestionTextArray = inputQuestionTextArray.filter(Boolean);

            this.questions[i]['textArray'] = inputQuestionTextArray;

            this.questions[i]['answersInput'] = [];

            for (let o = 0; o < inputQuestionTextArray.length - 1; o++) {
              this.questions[i].answersInput.push(null);
            }

          }

          this.questionResults.push({
            question_id: this.questions[i].id,
            has_answered_correctly: null,
          });
        }

        this.currentQuestion = this.questions[0];

        this.$forceUpdate();
      });
    },
    selectAnswer($event, answer) {

      for (let i = 0; i < this.currentQuestion.answers.length; i++) {
        this.currentQuestion.answers[i].selected = false;
        this.$forceUpdate();

      }

      answer.selected = $event;
    },
    nextQuestion() {

      this.areWeDrawing = false;
      
      if(this.vueCanvas){
        this.vueCanvas.reset()
        this.canvas = null;
        this.ctx = null;
      }
      //check if answer is correct
      //update the question results and increment the questions box at the top

      if (this.currentQuestion.type_id === 1) {
        for (let i = 0; i < this.currentQuestion.answers.length; i++) {

          let answer = this.currentQuestion.answers[i];

          // this.questionResults[this.currentQuestionIndex].has_answered_correctly = !!(answer.selected &&
          //     answer.is_correct_answer);

          if (answer.selected && answer.is_correct_answer) {
            this.questionResults[this.currentQuestionIndex].has_answered_correctly = true;
            break;
          } else if (answer.selected && !answer.is_correct_answer) {
            this.questionResults[this.currentQuestionIndex].has_answered_correctly = false;
          } else {
            this.questionResults[this.currentQuestionIndex].has_answered_correctly = false;
          }
        }
      }

      if (this.currentQuestion.type_id === 2) {

        let hasAnsweredCorrectly = true;

        let answers = this.currentQuestion.answers.sort((a, b) => a.position - b.position);

        for (let i = 0; i < this.currentQuestion.answersInput.length; i++) {

          if (answers[i].text !== this.currentQuestion.answersInput[i]) {
            hasAnsweredCorrectly = false;
          }
        }

        this.questionResults[this.currentQuestionIndex].has_answered_correctly = hasAnsweredCorrectly;
      }

      if (this.currentQuestion.type_id === 3) {

        let hasAnsweredCorrectly = true;

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {

          if (this.currentQuestion.answers[i].correct_answer.toLowerCase() !== this.currentQuestion.answersInput[i]) {
            hasAnsweredCorrectly = false;
          }
        }

        this.questionResults[this.currentQuestionIndex].has_answered_correctly = hasAnsweredCorrectly;
      }

      if (this.currentQuestion.type_id === 4) {

        for (let i = 0; i < this.currentQuestion.answers.length; i++) {

          let hasAnsweredCorrectly = true;

          let currentAnswer = this.currentQuestion.answers[i];

          let answerObjToCheck = this.currentQuestion.answers.find(obj => {
            return obj.id === currentAnswer.answer_linked;
          });

          if (!answerObjToCheck) {
            this.questionResults[this.currentQuestionIndex].has_answered_correctly = false;
          } else {
            if (answerObjToCheck.correct_answer !== currentAnswer.correct_answer) {
              hasAnsweredCorrectly = false;
            }

            this.questionResults[this.currentQuestionIndex].has_answered_correctly = hasAnsweredCorrectly;
          }

        }

      }

      //get nextQuestion
      //set it as current question and show it 
      this.currentQuestionIndex++;

      for (let i = 0; i < this.currentQuestion.answers.length; i++) {

        this.currentQuestion.answers[i].selected = false;
      }

      if (this.questions.length > this.currentQuestionIndex) {

        this.currentQuestion = this.questions[this.currentQuestionIndex];

        this.$forceUpdate();
        return;
      }

      // this.allPossibleColorsForLinkingAnswers = [
      //     '#4ac235',
      //     '#e088f4',
      //     '#a3784f',
      //     '#c98b31',
      //     '#a33162',
      //     '#1f1e6c',
      //     '#fc2c03',
      //     '#f0fc03',
      //     '#5e4a59',
      // ];

      this.showNextQuestionButton = false;
      this.currentQuestion = null;
      this.hasEnded = true;
    },
    goToNewRoute(link) {
      this.$router.push(link);
    },

    startDrawing() {
      this.areWeDrawing = !this.areWeDrawing;
      
      if (!this.areWeDrawing) {
        this.canvas = null;
        this.ctx = null;
      } else {
        this.canvas = document.getElementById('canvas');
        this.ctx = this.canvas.getContext('2d');
        this.vueCanvas = this.ctx;

        this.canvas.height = window.innerHeight + 1200;
        this.canvas.height = window.innerHeight + 1200;
        this.canvas.width = window.innerWidth;
      }
    },
  },

  mounted() {

  },

  created() {

    CategoryService.getOne(this.$route.params.category_id).then((res) => {

      this.currentCategory = res;

      this.videoLink = this.currentCategory.subsection.video_link;

    }).catch(() => {

    });

    this.getChallenge(this.$route.params.challenge_id);
    this.getAllSubsections();
    this.getCategories();
    this.getQuestions(this.$route.params.category_id);
  },

};
</script>

<style lang="scss" scoped>

.colorP {
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 100;
  border-radius: 20px;

  .v-color-picker {
    margin-left: 30px;
    margin-top: 30px;
  }

  .v-btn {
    margin-left: 130px;
    margin-top: 20px;
  }
}

.challenge-action {
  cursor: pointer;
}

#canvas {
  margin-left: 100px;
  position: absolute;
}

.canvas-inactive {
  pointer-events: none;
}


::v-deep .input-text-type-2 {
  width: 30% !important;
  display: inline-block !important;
  font-size: 25px !important;
}

::v-deep .input-text-type-3 {
  width: 30% !important;
  display: inline-block !important;
  font-size: 25px !important;
}

.connect-answers-box {
  width: 70%;
  border: 2px solid darkgrey;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
  padding: 8px;

  &:hover {
    cursor: pointer;
  }
}

.make-bigger {
  width: 80px !important;
  height: 80px !important;
  box-shadow: 0 3px 7px rgba(0, 0, 0, 0.6) !important;

}

.question-image {
  text-align: center;
}

.question-img {
  max-height: 400px;
  max-width: 40%;
}

.questions-check-boxes {

  ::v-deep .v-icon {
    font-size: 35px !important;
  }

}

.question-text-container {
  width: 100%;
  //height: 600px;
  overflow-y: scroll;
  font-size: 25px;
  white-space: pre-line;
  height: 80% !important;
  ::v-deep p {

    margin-bottom: 0 !important;
  }
  & > * {

    margin-bottom: 0 !important;
  }
}

::v-deep .question-text-container > p {

  margin-bottom: 0 !important;
}

.category-box {
  display: inline;
  float: left;
  //position: absolute;
  width: 60px;
  height: 60px;
  left: 126px;
  top: 165px;
  margin-left: 10px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}

.challenge-action {
  box-shadow: 0 2px 3px;
  margin-right: 15px;
}

.question-answer-box {
  display: inline;
  float: left;
  margin-left: 7px;
  width: 40px;
  height: 40px;

  background: #EBEBEB;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12);
  border-radius: 4px;

  flex: none;
  order: 0;
  flex-grow: 0;
}

.question-count-box {
  display: inline;
  float: left;
  margin-left: 20px;
  width: 107px;
  height: 40px;
  left: 1500px;
  top: 185px;

  background: #FFFFFF;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
  text-align: center;
}

.question-count-box p {
  font-family: 'Inter';
  font-style: normal;
  padding-top: 7px;
  font-size: 20px;
}

.subsection-box {
  cursor: pointer;
  width: 60px;
  height: 60px;
  left: 26px;
  top: 351px;
  margin-bottom: 20px;

  background: #90CCF4;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.14);
  border-radius: 4px;
}

.subsection-box p {
  text-align: center;
  padding-top: 5px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 30px;

  color: #000000;
}

.question-question p {
  //width: 678px;
  height: 24px;
  left: 960px;
  top: 1015px;

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  //font-size: 28px;
  line-height: 24px;
  /* identical to box height, or 86% */

  display: flex;
  align-items: center;

  color: #000000;
}

.question-type-box {
  padding: 0px;
  width: 32px;
  height: 32px;
  left: 915px;
  top: 1011px;

  background: rgba(93, 162, 213, 0.38);
  border-radius: 8px;
}

.question-type-box i {
  padding-top: 3px;
  padding-left: 4px;
}

.question-type-box p {
  padding-top: 3px;
  padding-left: 4px;
}

.drag-and-drop-btn {
  color: #0057AD;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px;
  font-size: 17px;
}
</style>
