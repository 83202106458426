<template>
    <div class="main-qa-container">
        <div class="main-page-title">
        </div>

        <div class="questions-contaier">
            <div class="current-question-container" v-for="(question, index) in questions"
                 :key="index + '_questions'">
                <div class="question-title" :style="{backgroundColor: question.color}">{{ question.question_text }}
                </div>
                <div class="question-answer" style="white-space: pre-wrap; line-heigth: 23px;" v-html="question.answer_text"></div>
            </div>
        </div>

        <div class="footer-container">
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>

import QuestionnaireService from '@/services/questionnaire.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
    name: 'questionsPage',

    components: {
        footerComponent,
    },

    data: function() {
        return {
            questions: [],
        };
    },

    methods: {

        goToNewRoute(link) {
            this.$router.push(link);
        },
        getQuestions() {
            QuestionnaireService.get().then((res) => {
                this.questions = res;
            });
        },
    },

    created() {
        this.getQuestions();
    },

};

</script>

<style lang="scss" scoped>

.questions-contaier {
    margin-bottom: 100px;
}

.main-page-title {
    text-align: left;
    margin-left: 200px;
    margin-bottom: 50px;
    color: #5DA2D5;
    padding-top: 50px;
    text-shadow: none;
    font-family: Inter;
    font-weight: bold;
}

.current-question-container {
    text-align: left;
    margin-left: 50px;
    margin-right: 50px;
}

.question-title {
    padding: 15px;
    color: white;
    font-family: Inter;
    font-weight: bold;
    text-shadow: none;
    text-aling: left;
}

.question-answer {

    text-shadow: none;
    padding: 20px;
    font-size: 1rem;
    font-style: italic;
    font-family: Inter;
    text-align: left;
}
</style>
