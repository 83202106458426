var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"persistent":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" Добави файл ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"pageFileForm"},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Файл")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6","md":"4"}},[_c('v-text-field',{attrs:{"label":"Заглавие*","required":""},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-textarea',{attrs:{"label":"Текст на страница"},model:{value:(_vm.text),callback:function ($$v) {_vm.text=$$v},expression:"text"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-select',{attrs:{"items":_vm.images,"label":"Картинка"},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.link,"width":"30"}}),_vm._v(_vm._s(item.name)+" ")]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('img',{attrs:{"src":item.link,"width":"30"}}),_vm._v(_vm._s(item.name)+" ")]}}]),model:{value:(_vm.image),callback:function ($$v) {_vm.image=$$v},expression:"image"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-file-input',{attrs:{"required":"","show-size":"","truncate-length":"46","label":"File input"},on:{"change":_vm.selectFile}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Затвори ")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.save}},[_vm._v(" Запиши ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }