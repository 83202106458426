import $axios from '@/plugins/axios'

class QuestionnaireService {

    get() {

        return $axios.get('/questionnaire-questions')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(id) {

        return $axios.get(`/questionnaire-questions/${id}`)
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    create(data) {

        return $axios.post(`/questionnaire-questions`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(id, data) {

        return $axios.put(`/questionnaire-questions/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    delete(id) {

        return $axios.delete(`/questionnaire-questions/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new QuestionnaireService();
