import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $axios from './plugins/axios'
import VueCookies from 'vue-cookies'

import 'vuetify/dist/vuetify.min.css'
// import '@mdi/font/css/materialdesignicons.css'
// import 'material-design-icons-iconfont/dist/material-design-icons.css'
import vuetify from './plugins/vuetify'

Vue.config.productionTip = false

import AppHeaderComponent from './components/Base/AppContentHeaderComponent.vue'
import AppSpinnerComponent from './components/Base/AppSpinnerComponent.vue'
import AppEmptyStateComponent from './components/Base/AppEmptyStateComponent.vue'
import AppContentGrid from './components/Base/AppContentGrid.vue'
import AppFloatFabButton from './components/Base/AppFloatFabButton.vue'
import AppMultiSelectInput from './components/Base/AppMultiSelectInput'
import { ToggleButton } from 'vue-js-toggle-button'
import TheLayoutWrap from '@/pages/Layout/TheLayoutWrap';
import TheLayoutAdminWrap from '@/pages/Layout/TheLayoutAdminWrap';
import Vuelidate from 'vuelidate'

Vue.component('AppHeaderComponent', AppHeaderComponent);
Vue.component('AppSpinnerComponent', AppSpinnerComponent);
Vue.component('AppEmptyStateComponent', AppEmptyStateComponent);
Vue.component('AppContentGrid', AppContentGrid);
Vue.component('AppFloatFabButton', AppFloatFabButton);
Vue.component('AppMultiSelectInput', AppMultiSelectInput);
Vue.component('ToggleButton', ToggleButton)
Vue.component('TheLayoutWrap', TheLayoutWrap)
Vue.component('TheLayoutAdminWrap', TheLayoutAdminWrap)

Vue.use(Vuelidate);

/** Axios plugin settings */
Vue.use({
  install(Vue) {
    Vue.prototype.$axios = $axios
  }
});

/** Vue cookies */
Vue.use(VueCookies)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
