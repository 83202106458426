<template>
    <v-row justify="center">
        <v-dialog
            v-model="dialog"
            persistent
            max-width="600px"
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    v-bind="attrs"
                    v-on="on"
                > Добави файл
                </v-btn>
            </template>

            <v-form
                ref="pageFileForm"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Файл</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                        label="Заглавие*"
                                        required
                                        v-model="title"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                    <v-textarea
                                        label="Текст на страница"
                                        v-model="text"
                                    ></v-textarea>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                    <v-select :items="images" label="Картинка" v-model="image">
                                        <template v-slot:selection="{ item }">
                                            <img :src="item.link" width="30">{{ item.name }}
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <img :src="item.link" width="30">{{ item.name }}
                                        </template>
                                    </v-select>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12"
                                >
                                    <v-file-input
                                        required
                                        show-size
                                        truncate-length="46"
                                        label="File input"
                                        @change="selectFile"
                                    ></v-file-input>
                                </v-col>

                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                        >
                            Затвори
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Запиши
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>

import {required} from 'vuelidate/lib/validators';

export default {
    name: 'PageFormModal',

    props: {},

    data: function() {

        return {
            dialog: false,
            images: [
                {
                    name: 'book',
                    link: process.env.VUE_APP_STORAGE_URL + 'book.png',
                },
                {
                    name: 'books',
                    link: process.env.VUE_APP_STORAGE_URL + 'books.png',
                },
                {
                    name: 'lists',
                    link: process.env.VUE_APP_STORAGE_URL + 'lists.png',
                },
                {
                    name: 'pin',
                    link: process.env.VUE_APP_STORAGE_URL + 'pin.png',
                },
                {
                    name: 'scale',
                    link: process.env.VUE_APP_STORAGE_URL + 'scale.png',
                },
                {
                    name: 'win',
                    link: process.env.VUE_APP_STORAGE_URL + 'win.png',
                },
            ],
            title: '',
            text: '',
            image: null,
            file: null,
        };
    },

    validations() {

        let pageFile = {
            title: {required},
        };

        return {
            pageFile,
        };
    },

    methods: {

        selectFile(file) {

            this.file = file;
        },

        save() {

            let pageFile = {
                title: this.title,
                text: this.text,
                imageUrl: this.image.link,
                file: this.file,
            };

            this.$emit('fileAdded', pageFile);
            this.closeDialog();
        },
        closeDialog() {

            this.$refs.pageFileForm.reset();
            this.dialog = false;
        },
    },

    created() {

    },
};
</script>

<style lang="scss" scoped>


</style>
