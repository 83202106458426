<template>
    <v-container>
        <v-row>
            <v-col>
                <p v-if="this.challenge">Подраздели на предизвикателсво {{this.challenge.name}}</p>
            </v-col>
            <v-col>
                <v-btn
                    color="white"
                    @click="openCreateDialog"
                > Създай подраздел
                </v-btn>
                <SubsectionFormModal @subsectionSaved="onSubsectionSaved"
                                    :subsection-prop="subsectionToEdit"
                                    :show-dialog-prop.sync="showDialog"
                                    :key="dialogKey"/>
            </v-col>
            <!--            <v-btn class="ma-4" @click="$router.push({name:'create-page'})">-->
            <!--                Създай предизвикателство-->
            <!--            </v-btn>-->
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Име
                            </th>
                            <th class="text-left">
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in subsections"
                            :key="index"
                        >
                            <td>{{ item.name }}</td>
                            <td><v-btn @click="$router.push({name: 'subsection-categories', params: {id: item.id}})">Категории</v-btn></td>
                            <td>
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="openEditDialog(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    medium
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChallengeService from '@/services/challenge.service';
import SubsectionFormModal from '@/components/Admin/Challenges/SubsectionFormModal';
import SubsectionService from '@/services/subsection.service';

export default {
    name: 'AdminSubsections',
    data: () => ({
        challenge: null,
        subsections: [],
        subsectionToEdit: null,
        showDialog: false,
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {SubsectionFormModal},
    computed: {},
    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        onSubsectionSaved() {
            this.loadSubsections();
        },
        loadChallenge() {

            ChallengeService.getOne(this.$route.params.id).then((res) => {

                this.challenge = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        loadSubsections() {

            ChallengeService.getSubsections(this.$route.params.id).then((res) => {

                this.subsections = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        openCreateDialog() {

            this.subsectionToEdit = null;
            this.dialogKey++;
            this.showDialog = true;
        },
        openEditDialog(challenge) {

            this.subsectionToEdit = challenge;
            this.dialogKey++;
            this.showDialog = true;
        },
        deleteItem(item) {
            
            if (confirm("Сигурни ли сте, че искате да изтриете този подраздел?") === true) {
                SubsectionService.delete(item.id).then(() => {

                    this.loadSubsections()
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
    },

    created() {

        this.loadChallenge();
        this.loadSubsections();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
