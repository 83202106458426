<template>
  <div class="main-container">
    <div class="teachers-section-1">
      <div class="teachers-section-1-title">
        <h1>Какво да правим у дома ?:</h1>
      </div>
      <p class="mb-0 teachers-p-text">
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
      </p>
    </div>
    <div class="teachers-section-2">
      <v-row>
        <v-col class="fourth-cols" id="fourth-col-1"></v-col>
        <v-col class="fourth-cols" id="fourth-col-2"></v-col>
        <v-col class="fourth-cols" id="fourth-col-3"></v-col>
        <v-col class="fourth-cols" id="fourth-col-4"></v-col>
      </v-row>
    </div>

    <div class="teachers-section-3">
      <v-row>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er </p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er</p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er</p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er</p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er</p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
        <v-col class="section-3-cols">
          <img src="../../assets/klamer.webp"/>
          <p class="section-3-cols-title">PDF Name</p>
          <p class="section-3-cols-sub-title">Lorem ipsum mcsjm  kdnsp idckdmck kdcjdmf d erw r eer ege er</p>
          <v-btn>Свали PDF</v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="footer-container">
      <footerComponent></footerComponent>
    </div>
  </div>
</template>

<script>

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
  name: 'TeachersPage',

  components: {
    footerComponent
  },

  data: function() {
    return {

    };
  },

  created(){

  },

  methods: {

    goToNewRoute(link){
      this.$router.push(link);
    }
  }

}
</script>

<style lang="scss" scoped>

.teachers-section-3{
  margin-bottom: 100px;
  margin-top: 100px;
}

.section-3-cols{
  margin: 30px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);

  .v-btn{
    background-color: #5DA2D5!important;
    color: white;
  }

}

.section-3-cols-title{
  text-shadow: none;
  font-size: 1.5rem;
  font-weight: bold;
  font-family: Inter;
}

.section-3-cols-sub-title{
  text-shadow: none;
  font-size: 0.9rem;
  font-family: Inter;
  color: darkgrey;
}

.teachers-section-1-title{
  text-align: left;
  margin-left: 200px;
  color: #5DA2D5;
  padding-top: 50px;
  text-shadow: none;
  font-family: Inter;
  font-weight: bold;
}

.teachers-section-1{

  background: linear-gradient(0deg, rgba(206, 204, 204, 0.2), rgba(206, 204, 204, 0.2)), url(../../assets/photo-background.webp);

  p{
    text-shadow: none;
    padding-top: 40px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
    font-size: 1.5rem;
    font-style: italic;
    font-family: Inter;
    line-height: 2.8rem;
    text-align: left;
  }
}


.teachers-section-2{

  margin-bottom: 30px;

  .fourth-cols{
    width: 100%;
    height: 40px!important;
  }

  #fourth-col-1{
    background-color: #F68887;
  }

  #fourth-col-2{
    background-color: #F3D24F;
  }

  #fourth-col-3{
    background-color: #90CCF4;
  }

  #fourth-col-4{
    background-color: #5DA2D5;
  }

}

</style>
