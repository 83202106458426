import $axios from '@/plugins/axios'

class QuestionTypeService {

    get() {

        return $axios.get('/question-types')
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new QuestionTypeService();
