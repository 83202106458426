import $axios from '@/plugins/axios'

class GeneralSettingService {

    get() {

        return $axios.get('/general-settings')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(data) {

        return $axios.post(`/general-settings`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getVisits(){

        return $axios.get('/visits')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })

    }

    updateVisits(){

        return $axios.post('/visits')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })

    }

}

export default new GeneralSettingService();
