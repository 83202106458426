<template>
    <div class="main-container">

        <v-container fluid style="margin-bottom: 20px;">
            <v-row>
                <v-col>
                    <div
                        v-for="(subsection, index) in subsections"
                        :key="index + '_subsection'">
                        <v-btn @click="getSubsectionCategories(subsection)" :color="subsection.color" width="300" style="margin-top: 20px; margin-bottom: 20px;">{{ subsection.name }}
                        </v-btn>
                        <v-container v-if="subsection.categories && subsection.categories.length > 0">
                            <v-row>
                                <v-col>
                                    <div
                                        v-for="(category, index) in subsection.categories"
                                        :key="index + '_category'">
                                        <v-btn  :color="subsection.color" width="300" style="margin-bottom: 20px; margin-top: 20px;"
                                            @click="openChallengePage(subsection, category)">
                                            {{ category.name }}
                                        </v-btn>
                                    </div>
                                </v-col>
                            </v-row>
                        </v-container>
                    </div>
                </v-col>
            </v-row>
        </v-container>
        <div class="footer-container">
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>

import ChallengeService from '@/services/challenge.service';
import SubsectionService from '@/services/subsection.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
    name: 'SubsectionsPage',

    components: {
        footerComponent,
    },

    data: function() {
        return {
            challenge_id: null,
            subsections: [],
        };
    },

    methods: {

        openChallengePage(subsection, category) {
            this.$router.push({
                name: 'challenge',
                params: {
                    challenge_id: this.challenge_id,
                    subsection_id: subsection.id,
                    category_id: category.id,
                },
            });
        },
        getChallengeSubsections(id) {
            ChallengeService.getSubsections(id).then((res) => {
                this.subsections = res;
                this.$forceUpdate();
            });
        },
        getSubsectionCategories(subsection) {
            console.log(subsection);
            SubsectionService.getCategories(subsection.id).then((res) => {
                subsection.categories = res;
                this.$forceUpdate();
            });
        },
        goToNewRoute(link) {
            this.$router.push(link);
        },
    },

    created() {
        this.challenge_id = this.$route.params.id;
        this.getChallengeSubsections(this.challenge_id);
    },

};
</script>

<style lang="scss" scoped>

</style>
