export default function auth ({ next, store, nextMiddleware }) {

    console.log("auth middleware");

    let isAuthenticated = store.getters['auth/isAuthenticated']();

    if (!isAuthenticated){

        return next({
            path: '/login'
        });
    }

    return nextMiddleware();
}
