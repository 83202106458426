<template>
    <v-app id="inspire" style="font-family: Montserrat Alternates;">
        <div>
            <v-app-bar dense dark class="app-bar-main">
                <v-btn icon @click.stop="drawer = !drawer" class="drawer-btn">
                    <v-icon x-large>list</v-icon>
                </v-btn>
                <v-btn icon @click.stop="$router.push('/')" class="logo-btn">
                    <v-lazy transition="fade-transition">
                        <img alt="my-logo" class="shrink ml-10" src="../../assets/logo.webp" width="200"/>
                    </v-lazy>
                </v-btn>

                <div class="menu-desktop">
                    <div class="d-flex justify-center align-center">
                        <v-btn
                            v-for="(btn, index) in btns"
                            :key="index + '_btns-menu'"
                            class="white--text ma-4 btn-menu-desktop"
                            @click.prevent="goToNewRoute(btn.link)"
                        >

                            {{ btn.text }}
                        </v-btn>
                    </div>
                </div>
            </v-app-bar>

        </div>

        <!--<md-app-content>-->
        <div class="app-content">
            <router-view></router-view>
        </div>
        <!--</md-app-content>-->
    </v-app>
</template>

<script>

import PageService from '@/services/page.service';

export default {

    data: () => ({
        isAdmin: false,
        isLogin: false,
        drawer: false,
        btns: [
            {
                text: 'Ученик',
                link: '/challenges',
            },
            {
                text: 'Въпроси',
                link: '/questionnaire',
            },
        ],
        pages: []
    }),

    components: {},

    methods: {

        getPages() {
            PageService.get().then((res) => {

                this.pages = res;
                
                for (let i = 0; i < this.pages.length; i++){
                    
                    let index = i + 1                    
                    let btn = {
                        text: this.pages[i].title,                                                
                        link: '/page/' + this.pages[i].slug,                                                
                    }
                    
                    this.btns.splice(index, 0, btn);
                }
            });
        },
        goToNewRoute(link) {
            this.$router.push(link);
        },
    },

    created() {
        this.getPages()
    },

    watch: {},
};
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css?family=Inter');

#inspire {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0);
}

.nav-drawer {
    background-color: #5DA2D5 !important;

    @media only screen and (min-width: 860px) {
        display: none;
    };
}

.drawer-btn {
    @media only screen and (min-width: 860px) {
        display: none;
    };
}

.logo-btn {

    margin-left: 45px !important;

    border-radius: 0%;

    @media only screen and (max-width: 860px) {
        display: none;
    };
}

.logo-btn::before {
    opacity: 0 !important;
}

.menu-desktop {

    @media only screen and (max-width: 860px) {
        display: none !important;
    };
}

.mobile-menu-containers {
    text-align: left;
    border-bottom: 1px solid grey;
    margin-top: 20px;
}

.app-bar-main {
    background-color: #5DA2D5 !important;

    height: 80px !important;
    padding-top: 15px !important;
}

::v-deep .app-bar-main {
    .v-toolbar__content {
        justify-content: space-between !important;
    }
}

.static-texts {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0);
    margin-right: 40px !important;
    color: white;
}

.icon-btns {
    margin-left: 80px;
}

.social-icons-main-menu {
    margin-right: 20px;
}

.main-bar {
    background-image: linear-gradient(to right, rgb(0, 188, 212) 30%, rgb(10, 148, 200) 70%);
    padding-top: 25px;
    padding-bottom: 25px;
}

.main-bar-first-cont-desktop {
    @media only screen and (max-width: 860px) {
        display: none;
    };
}

.main-bar-first-cont-mobile {
    @media only screen and (min-width: 860px) {
        display: none;
    };
}

.dropdown-list {
    font-size: 0.9rem;
    color: white !important;
}

.list-main-cont {
    /*background-color: #ED8639!important;*/
    background-color: #5DA2D5 !important;


}

.btn-menu-desktop {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0);
    font-family: Inter;
    font-weight: 700;
    text-transform: none;
    background: transparent !important;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0) !important;

    /*background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;*/
}

.list-drop-btn {
    margin-top: 1rem;
    border-bottom: 1px dotted saddlebrown !important;
}

.menuable__content__active {
    top: 131px !important;
}

.mobile-btn-text-white {
    white-space: normal;
    text-overflow: clip;
    line-height: 1.3rem !important;

    color: white !important;
    font-weight: 200;
}

.href-to-unstyle {
    color: transparent !important;
}

::v-deep .v-list-group__header__append-icon .v-icon {
    color: white !important;
}

/*.style-diplomas{*/
/*margin-right: 10px!important;*/
/*}*/
/**/
::v-deep .v-list-item__icon {
    margin-right: 15px !important;
}

.mobile-menu-icon {
    margin-top: 12px !important;
}

</style>
