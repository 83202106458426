<template>
    <v-container>
        <v-row>
            <v-col>
                <p v-if="this.subsection">Категории на подраздел {{this.subsection.name}}</p>
            </v-col>
            <v-col>
                <v-btn
                    color="white"
                    @click="openCreateDialog"
                > Създай категория
                </v-btn>
                <CategoryFormModal @categorySaved="onCategorySaved"
                                    :category-prop="categoryToEdit"
                                    :show-dialog-prop.sync="showDialog"
                                    :key="dialogKey"/>
            </v-col>
            <!--            <v-btn class="ma-4" @click="$router.push({name:'create-page'})">-->
            <!--                Създай предизвикателство-->
            <!--            </v-btn>-->
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Име
                            </th>
                            <th class="text-left">
                                
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in categories"
                            :key="index"
                        >
                            <td>{{ item.name }}</td>
                            <td><v-btn @click="$router.push({name: 'category-questions', params: {category_id: item.id}})">Въпроси</v-btn></td>
                            <td>                                
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="openEditDialog(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    medium
                                    @click="openDeleteDialog(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import SubsectionService from '@/services/subsection.service';
import CategoryFormModal from '@/components/Admin/Challenges/CategoryFormModal';
import CategoryService from '@/services/category.service';

export default {
    name: 'AdminCategories',
    data: () => ({
        subsection: null,
        categories: [],
        categoryToEdit: null,
        showDialog: false,
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {
        CategoryFormModal
    },
    computed: {},
    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        onCategorySaved() {
            this.loadCategories();
        },
        loadSubsection() {

            SubsectionService.getOne(this.$route.params.id).then((res) => {

                this.subsection = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        loadCategories() {

            SubsectionService.getCategories(this.$route.params.id).then((res) => {

                this.categories = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        openCreateDialog() {

            this.categoryToEdit = null;
            this.dialogKey++;
            this.showDialog = true;
        },
        openEditDialog(category) {

            this.categoryToEdit = category;
            this.dialogKey++;
            this.showDialog = true;
        },
        openDeleteDialog(item) {
            
            if (confirm("Сигурни ли сте, че искате да изтриете тази категория?") === true) {
                CategoryService.delete(item.id).then(() => {

                    this.loadCategories();
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
    },

    created() {

        this.loadSubsection();
        this.loadCategories();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
