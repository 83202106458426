<template>
    <v-row>
        <v-dialog
            v-model="showDialog"
            persistent
            max-width="600px"
        >
            <v-form
                ref="subsectionForm"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Подраздел</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                        label="Име*"
                                        required
                                        v-model="subsection.name"
                                    ></v-text-field>
                                </v-col>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-color-picker
                                        dot-size="25"
                                        swatches-max-height="200"
                                        v-model="subsection.color"
                                    ></v-color-picker>
                                </v-col>
                            </v-row>
                          <v-row>
                            <v-col>
                              <v-text-field
                                  label="Видео*"
                                  required
                                  v-model="subsection.video_link"
                              ></v-text-field>
                            </v-col>
                          </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                        >
                            Затвори
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Запиши
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>

import {required} from 'vuelidate/lib/validators';
import SubsectionService from '@/services/subsection.service';

export default {
    name: 'SubsectionFormModal',

    props: {
        showDialogProp: {
            type: Boolean,
            default: false,
        },
        subsectionProp: {
            type: Object,
        },
    },

    data: function() {

        return {
            showDialog: false,
            isEditFormModal: false,
            subsection: {
                name: '',
                color: '',
                video_link: ''
            },
            challengeId: null,
        };
    },

    watch: {
        showDialogProp() {

            if (this.showDialog) {

                this.showDialog = true;
            }
        },
    },

    validations() {

        return {
            name: {required},
            color: {required},
        };
    },

    methods: {

        selectFile(file) {

            this.file = file;
        },

        save() {
            let data = {
                name: this.subsection.name,
                color: this.subsection.color,
                video_link: this.subsection.video_link,
                challenge_id: this.challengeId
            };
            
            if (this.isEditFormModal) {

                SubsectionService.update(this.subsection.id, data).then(() => {

                    this.$emit('subsectionSaved');
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            } else {

                SubsectionService.create(data).then(() => {

                    this.$emit('subsectionSaved');
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }

            this.closeDialog();
        },
        closeDialog() {

            this.$refs.subsectionForm.reset();
            this.showDialog = false;
        },
    },

    mounted() {
        
        this.challengeId = this.$route.params.id;

        this.showDialog = this.showDialogProp;
        
        if (this.subsectionProp) {

            this.isEditFormModal = true;
            this.subsection = Object.assign({}, this.subsectionProp);
        }
    },
};
</script>

<style lang="scss" scoped>


</style>
