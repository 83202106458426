<template>
    <v-row>
        <v-dialog
            v-model="showDialog"
            persistent
            max-width="600px"
        >
            <v-form
                ref="challengeForm"
            >
                <v-card>
                    <v-card-title>
                        <span class="text-h5">Предизвикателство</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col
                                    cols="12"
                                    sm="6"
                                    md="4"
                                >
                                    <v-text-field
                                        label="Име*"
                                        required
                                        v-model="challenge.name"
                                    ></v-text-field>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="closeDialog"
                        >
                            Затвори
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="save"
                        >
                            Запиши
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </v-row>
</template>

<script>

import {required} from 'vuelidate/lib/validators';
import ChallengeService from '@/services/challenge.service';

export default {
    name: 'ChallengeFormModal',

    props: {
        showDialogProp: {
            type: Boolean,
            default: false,
        },
        challengeProp: {
            type: Object,
        },
    },

    data: function() {

        return {
            showDialog: false,
            isEditFormModal: false,
            challenge: {
                name: '',
            },
        };
    },

    watch: {
        showDialogProp() {

            if (this.showDialog) {

                this.showDialog = true;
            }
        },
    },

    validations() {

        return {
            title: {required},
        };
    },

    methods: {

        selectFile(file) {

            this.file = file;
        },

        save() {
            let data = {
                name: this.challenge.name
            };
            
            if (this.isEditFormModal) {

                ChallengeService.update(this.challenge.id, data).then(() => {

                    this.$emit('challengeSaved');
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            } else {

                ChallengeService.create(data).then(() => {

                    this.$emit('challengeSaved');
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }


            this.closeDialog();
        },
        closeDialog() {

            this.$refs.challengeForm.reset();
            this.showDialog = false;
        },
    },

    mounted() {

        this.showDialog = this.showDialogProp;

        console.log(this.challengeProp)
        
        if (this.challengeProp) {

            this.isEditFormModal = true;
            this.challenge = Object.assign({}, this.challengeProp);
        }
    },
};
</script>

<style lang="scss" scoped>


</style>
