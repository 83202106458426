import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbarMessageData: {
            message: '',
            duration: 3000,
            mode: 'success'
        },
    },

    getters: {
        getSnackbarMessage(state) {
            return state.snackbarMessageData;
        }
    },

    mutations: {
        setSnackbarMessage(state, {message, duration = 4000, mode = 'success', buttonText = 'Close'}) {
            state.snackbarMessageData = {message, duration, mode, buttonText};
        }
    },
    actions: {
        showSnackbarMessage(vuexContext, messageData) {
            vuexContext.commit('setSnackbarMessage', messageData);
        }
    },
    modules: {
        auth
    }
})
