<template>
    <div class="component-wrap">
        <v-textarea
            name="input-7-1"
            label="Текст на главна страница"
            v-model="settings.home_page_text"
        ></v-textarea>

        <v-text-field label="Телефонен номер" v-model="settings.phone"></v-text-field>
        <v-text-field label="Емейл" v-model="settings.email"></v-text-field>
        <v-text-field label="Адрес" v-model="settings.address"></v-text-field>

        <!--        <v-file-input-->
        <!--            accept="image/*"-->
        <!--            label="Качи ново лого"-->
        <!--        ></v-file-input>-->

        <v-btn class="ma-4" @click="saveSettings">
            Запази
        </v-btn>
    </div>
</template>

<script>
import GeneralSettingService from '@/services/general-setting.service';

export default {
    name: 'GeneralSettings',
    data: () => ({
        settings: [],
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {},
    computed: {},

    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        getSettings() {

            GeneralSettingService.get().then((res) => {

                this.settings = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        saveSettings() {

            GeneralSettingService.update(this.settings).then((res) => {

                this.settings = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
    },

    created() {

        this.getSettings();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
