import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/pages/Home/Home.vue';
import GeneralPage from '@/pages/GeneralPage/GeneralPage';
import WhatWeDo from '@/pages/WhatWeDo/WhatWeDo.vue';
import Questionnaire from '@/pages/QandA/QandA.vue';
import GeneralSettings from '@/pages/Admin/GeneralSettings/GeneralSettings';
import Pages from '@/pages/Admin/Pages/Pages';
import TheLayoutAdminWrap from '@/pages/Layout/TheLayoutAdminWrap';
import TheLayoutWrap from '@/pages/Layout/TheLayoutWrap';
import CreatePage from '@/pages/Admin/Pages/CreatePage';
import AdminQuestions from '@/pages/Admin/Questionnaire/Questions';
import QuestionnaireQuestion
	from '@/pages/Admin/Questionnaire/QuestionnaireQuestion';
import AdminChallenges
	from '@/pages/Admin/Challenges/Challenges';
import AdminSubsections from '@/pages/Admin/Challenges/Subsections';
import AdminCategories from '@/pages/Admin/Challenges/Categories';
import AdminCategoryQuestions from '@/pages/Admin/Challenges/Questions';
import CategoryQuestion from '@/pages/Admin/Challenges/CategoryQuestion';
import ChallengesPage from '@/pages/Challenges/ChallengesPage';
import ChallengePage from '@/pages/Challenges/ChallengePage';
import PdfPage from '@/pages/GeneralPage/PdfPage';
import SubsectionsPage from '@/pages/Challenges/SubsectionsPage';

import auth from './middleware/auth'
import guest from './middleware/guest'
import store from '../store'
import middlewarePipeline from './middlewarePipeline'

Vue.use(VueRouter);

const routes = [
	{
		path: '',
		redirect: '/',
		component: TheLayoutWrap,
		children: [
			{
				path: '/',
				name: 'home',
				component: Home,
			},
			{
				path: '/page/:slug',
				name: 'general-page',
				component: GeneralPage,
			},
			{
				path: '/pdf/:id',
				name: 'pdf-page',
				component: PdfPage,
			},
			{
				path: '/whatwedo',
				name: 'whatwedo',
				component: WhatWeDo,
			},
			{
				path: '/questionnaire',
				name: 'questionnaire',
				component: Questionnaire,
			},
			{
				path: '/challenges',
				name: 'challenges',
				component: ChallengesPage,
			},
			{
				path: '/challenges/:id',
				name: 'challenge-subsections',
				component: SubsectionsPage,
			},
			{
				path: '/challenges/:challenge_id/subsections/:subsection_id/categories/:category_id',
				name: 'challenge',
				component: ChallengePage,
			},
		],
	},
	{
		path: '/admin',
		redirect: '/admin/general-settings',
		component: TheLayoutAdminWrap,
		children: [
			{
				path: 'general-settings',
				name: 'general-settings',
				component: GeneralSettings,
			},
			{
				path: 'pages',
				name: 'pages',
				component: Pages,
			},
			{
				path: 'create-page',
				name: 'create-page',
				component: CreatePage,
			},
			{
				path: 'update-page/:slug',
				name: 'update-page',
				component: CreatePage,
			},
			{
				path: 'questionnaire',
				name: 'admin-questionnaire',
				component: AdminQuestions,
			},
			{
				path: 'create-questionnaire-question',
				name: 'create-questionnaire-question',
				component: QuestionnaireQuestion,
			},
			{
				path: 'edit-questionnaire-question/:id',
				name: 'edit-questionnaire-question',
				component: QuestionnaireQuestion,
			},
			{
				path: 'questionnaire-question/:id',
				name: 'update-questionnaire-question',
				component: QuestionnaireQuestion,
			},
			{
				path: 'challenges',
				name: 'admin-challenges',
				component: AdminChallenges,
			},
			{
				path: 'challenges/:id/subsections',
				name: 'admin-challenge-subsections',
				component: AdminSubsections,
			},
			{
				path: 'subsections/:id/categories',
				name: 'subsection-categories',
				component: AdminCategories,
			},
			{
				path: 'categories/:category_id/questions',
				name: 'category-questions',
				component: AdminCategoryQuestions,
			},
			{
				path: 'categories/:category_id/create-question',
				name: 'create-category-question',
				component: CategoryQuestion,
			},
			{
				path: 'update-question/:question_id',
				name: 'update-category-question',
				component: CategoryQuestion,
			},
		],
		meta: {
			middleware: [auth]
		}
	},	
	{
		path: '/login',
		component: () => import('@/pages/Admin/Auth/AuthLogin.vue'),
		meta: {
			middleware: [guest]
		}
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeEach((to, from, next) => {
	
	if (!to.meta.middleware && !(to.matched.length > 0 && to.matched[0].meta.middleware)) {
		return next()
	}
	
	const middleware = to.meta.middleware || to.matched[0].meta.middleware;
	
	const context = {
		to,
		from,
		next,
		store
	};
	
	return middleware[0]({
		...context,
		nextMiddleware: middlewarePipeline(context, middleware, 1)
	});
});

export default router;
