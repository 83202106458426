<template>
    <div class="main-container">
        <div v-if="file">
            <div class="teachers-section-1">
                <div class="teachers-section-1-title">
                    <h1>{{ file.pages[0].pivot.file_title }}</h1>
                </div>
                <p class="mb-0 teachers-p-text">
                    {{ file.pages[0].pivot.file_text }}
                </p>
            </div>
            <div class="teachers-section-2">
                <v-row>
                    <v-col class="fourth-cols" id="fourth-col-1"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-2"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-3"></v-col>
                    <v-col class="fourth-cols" id="fourth-col-4"></v-col>
                </v-row>
            </div>

            <div class="teachers-section-3">
                <v-row>
                    <v-col cols="12">
                        <iframe :src="file.url" style="width:100%; height:700px;"></iframe>
                    </v-col>
                </v-row>
            </div>
        </div>

        <div class="footer-container">
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>

import FileService from '@/services/file.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
    name: 'PdfPage',

    components: {
        footerComponent,
    },

    data: function() {
        return {
            file: null,
        };
    },

    watch: {
        '$route.params.id': function(id) {
            this.loadFile(id);
        },
    },

    methods: {
        loadFile(id) {
            FileService.getOne(id).then((res) => {
                this.file = res;
                console.log(this.file)
            });
        },
    },

    created() {

        if (this.$route.params.id) {
            this.loadFile(this.$route.params.id);
        }
    },

};
</script>

<style lang="scss" scoped>

.teachers-section-3 {
    margin-bottom: 100px;
    margin-top: 100px;
}

.section-3-cols {
    margin: 30px;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.6);

    .v-btn {
        background-color: #5DA2D5 !important;
        color: white;
    }

}

.section-3-cols-title {
    text-shadow: none;
    font-size: 1.5rem;
    font-weight: bold;
    font-family: Inter;
}

.section-3-cols-sub-title {
    text-shadow: none;
    font-size: 0.9rem;
    font-family: Inter;
    color: darkgrey;
}

.teachers-section-1-title {
    text-align: left;
    margin-left: 200px;
    color: #5DA2D5;
    padding-top: 50px;
    text-shadow: none;
    font-family: Inter;
    font-weight: bold;
}

.teachers-section-1 {

    background: linear-gradient(0deg, rgba(206, 204, 204, 0.2), rgba(206, 204, 204, 0.2)), url(../../assets/photo-background.webp);

    p {
        text-shadow: none;
        padding-top: 40px;
        padding-bottom: 100px;
        padding-left: 200px;
        padding-right: 200px;
        font-size: 1.5rem;
        font-style: italic;
        font-family: Inter;
        line-height: 2.8rem;
        text-align: left;
    }
}


.teachers-section-2 {

    margin-bottom: 30px;

    .fourth-cols {
        width: 100%;
        height: 40px !important;
    }

    #fourth-col-1 {
        background-color: #F68887;
    }

    #fourth-col-2 {
        background-color: #F3D24F;
    }

    #fourth-col-3 {
        background-color: #90CCF4;
    }

    #fourth-col-4 {
        background-color: #5DA2D5;
    }

}

</style>
