<template>
    <v-container>
        <v-row>
            <v-col>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    > Създай страница
                    </v-btn>
                </template>

                <v-form
                    ref="form"
                    lazy-validation
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Страница</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                            label="Заглавие*"
                                            required
                                            v-model="title"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                      <span>Текст на страница*</span>
                                      <vue-editor
                                        v-model="text"></vue-editor>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-row v-if="files.length > 0 || newFiles.length > 0">
                                            Файлове:
                                            <v-col v-for="(file, index) in files" :key="index + '_file'"
                                                   cols="3"
                                                   md="3"
                                                   sm="3"
                                            >
                                                <v-card>
                                                    <div>
                                                        <v-img :src="file.pivot.file_image_url" max-width="50"></v-img>
                                                        <p>Зафлавие: {{ file.pivot.file_title }}</p>
                                                        <p>Текст: {{ file.pivot.file_text }}</p>
                                                        <p>Линк: {{file.url}}</p>
                                                    </div>
                                                    <div>
                                                        <v-icon
                                                            medium
                                                            class="mr-2"
                                                            @click="deleteFile(index, file)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                            <v-col v-for="(file, index) in newFiles" :key="index + '_new_file'"
                                                   cols="3"
                                                   md="3"
                                                   sm="3"
                                            >
                                                <v-card>
                                                    <div>
                                                        <v-img :src="file.imageUrl" max-width="50"></v-img>
                                                        <p>Зафлавие: {{ file.title }}</p>
                                                        <p>Текст: {{ file.text }}</p>
                                                        <p>Линк: {{file.url}}</p>
                                                    </div>
                                                    <div>
                                                        <v-icon
                                                            medium
                                                            class="mr-2"
                                                            @click="removeNewFile(index)">
                                                            mdi-delete
                                                        </v-icon>
                                                    </div>
                                                </v-card>
                                            </v-col>
                                        </v-row>
                                        <PageFileFormModal
                                            @fileAdded="addFile"
                                        />
                                    </v-col>
                                </v-row>
                              <v-row>
                                Видео линкове
                                <v-col
                                    cols="12"
                                    sm="12"
                                    md="12">
                                    <v-row v-for="(videoLink, index) in videoLinks"
                                           :key="index">
                                      <v-text-field
                                          label="Видео линк"
                                          :value="videoLinks[index]"
                                          v-model="videoLinks[index]"
                                      ></v-text-field>
                                    </v-row>
                                  <v-btn @click="addVideoLinkField">Добави видео линк</v-btn>
                                </v-col>
                              </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="savePage"
                            >
                                Запиши
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PageFileFormModal from '@/components/Admin/Pages/PageFileFormModal';
import PageService from '@/services/page.service';
import { VueEditor } from "vue2-editor";

export default {
    name: 'CreatePage',
    data: () => ({
        id: null,
        title: null,
        text: null,
        files: [],
        newFiles: [],
        videoLinks: [],
        deletedFiles: [],
        loadingSpinner: true,
        isEditPage: false,
    }),
    components: {
        PageFileFormModal,
        VueEditor,
    },
    computed: {},

    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

      addVideoLinkField(){

        this.videoLinks.push('');
      },
        addFile(pageFile) {

            console.log(pageFile);
            this.newFiles.push(pageFile);
            this.$forceUpdate();
        },
        removeNewFile(index){

            this.newFiles.splice(index, 1);
        },
        deleteFile(index, file){

            if (file.id) {

                this.deletedFiles.push(file.id);
            }

            this.files.splice(index, 1);
        },
        getPage(slug) {
            PageService.getOne(slug).then((res) => {

                this.id = res.id;
                this.title = res.title;
                this.text = res.text;
                this.files = res.files;
                this.videoLinks = res.video_links;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        savePage() {

            if (this.isEditPage) {

                this.updatePage();
                return;
            }

            this.createPage();
        },
        updatePage() {

            let bodyFormData = new FormData();
            bodyFormData.set('title', this.title);
            bodyFormData.set('text', this.text);

            let videoLinks = this.videoLinks.filter(Boolean);
            let videoLinksJson = JSON.stringify(videoLinks);
            bodyFormData.set('video_links', videoLinksJson);

            if (this.deletedFiles.length > 0) {

                bodyFormData.set(`files_to_delete`, JSON.stringify(this.deletedFiles));
            }

            for (let i = 0; i < this.newFiles.length; i++) {

                let pageFile = this.newFiles[i];

                bodyFormData.set(`pageFiles[${i}]`, JSON.stringify({
                    title: pageFile.title,
                    text: pageFile.text,
                    image_url: pageFile.imageUrl,
                }));
                bodyFormData.set(`files[${i}]`, pageFile.file);
            }

            PageService.update(this.id, bodyFormData).then(() => {

                this.$router.push('/admin/pages');
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        createPage() {

            let bodyFormData = new FormData();
            bodyFormData.set('title', this.title);
            bodyFormData.set('text', this.text);

            for (let i = 0; i < this.files.length; i++) {

                let pageFile = this.files[i];

                bodyFormData.set(`pageFiles[${i}]`, JSON.stringify({
                    title: pageFile.title,
                    text: pageFile.text,
                    image_url: pageFile.imageUrl,
                }));
                bodyFormData.set(`files[${i}]`, pageFile.file);
            }

            PageService.create(bodyFormData).then(() => {

                this.$router.push('/admin/pages');
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
    },

    created() {

        if (this.$route.params.slug) {
            this.isEditPage = true;
            this.getPage(this.$route.params.slug);
        }
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
