<template>
    <v-container>
        <v-row>
            <v-btn class="ma-4" @click="$router.push({name:'create-page'})">
                Създай страница
            </v-btn>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Заглавие
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="item in pages"
                            :key="item.title"
                        >
                            <td>{{ item.title }}</td>
                            <td>
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="editPage(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    medium
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import PageService from '@/services/page.service';

export default {
    name: 'AdminPages',
    data: () => ({
        pages: [],
        page: null,
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {},
    computed: {},

    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        loadPages() {

            PageService.get().then((res) => {

                this.pages = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        editPage(page) {

            console.log(page)
            this.$router.push({
                name:'update-page',
                params: {slug: page.slug}
            })
        },
        deleteItem(item) {

            if (confirm("Сигурни ли сте, че искате да изтриете тази страница?") === true) {
                PageService.delete(item.id).then(() => {

                    this.loadPages()
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
    },

    created() {

        this.loadPages();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
