<template>
    <v-container>
        <v-row>
            <v-col>
                <v-form
                    ref="form"
                    lazy-validation
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Въпрос за категория</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                      <span>Въпрос*</span>
                                      <vue-editor
                                        v-model="question.question"></vue-editor>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                      <span>Текст*</span>
                                      <vue-editor
                                        v-model="question.text"></vue-editor>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-textarea
                                            label="Страничен Текст"                                            
                                            v-model="question.side_text"
                                        ></v-textarea>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-img
                                            v-if="question.images.length> 0"
                                            :src="question.images[0].url"
                                            max-width="206"
                                        ></v-img>
                                        <v-file-input
                                            required
                                            show-size
                                            truncate-length="46"
                                            label="Избери картинка"
                                            @change="selectFile"
                                        ></v-file-input>
                                    </v-col>
                                    <v-col
                                        class="d-flex"
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-select
                                            :items="questionTypes"
                                            label="Тип въпрос*"
                                            item-text="name"
                                            item-value="id"
                                            v-model="question.type_id"
                                            required
                                        ></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    Отговори:
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-card v-for="(answer, index) in question.answers"
                                                :key="index">
                                            <v-container>
                                                <v-row>
                                                    <v-col>
                                                        <v-text-field
                                                            label="Отговор*"
                                                            required
                                                            v-model="question.answers[index].text"
                                                        ></v-text-field>
                                                        <v-row>
                                                            <v-col cols="6">
                                                                <v-switch v-if="question.type_id == 1"
                                                                    v-model="question.answers[index].is_correct_answer"
                                                                    label="Верен отговор"
                                                                ></v-switch>
                                                                <v-text-field v-if="question.type_id == 2"
                                                                    label="Номер на позиция*"
                                                                    required
                                                                    v-model="question.answers[index].position"
                                                                ></v-text-field>
                                                                <v-text-field v-if="question.type_id == 3"
                                                                          v-model="question.answers[index].correct_answer"
                                                                          label="Верен отговор"
                                                                ></v-text-field>
                                                                <v-text-field v-if="question.type_id == 3"
                                                                    label="Номер на позиция*"
                                                                    required
                                                                    v-model="question.answers[index].position"
                                                                ></v-text-field>
                                                                <v-text-field v-if="question.type_id == 4"
                                                                          v-model="question.answers[index].correct_answer"
                                                                          label="Верен отговор"
                                                                ></v-text-field>
                                                              <v-radio-group v-if="question.type_id == 4" v-model="question.answers[index].position">
                                                                <v-radio
                                                                    v-for="(n, indexRadio) in ['Ляво', 'Дясно']"
                                                                    :key="n"
                                                                    :label="`Позиция ${n}`"
                                                                    :value="indexRadio"
                                                                ></v-radio>
                                                              </v-radio-group>
                                                            </v-col>
                                                            <v-col cols="6" style="text-align: right;">
                                                                <v-icon
                                                                    medium
                                                                    class="mr-2"
                                                                    @click="deleteAnswer(index, answer)">
                                                                    mdi-delete
                                                                </v-icon>
                                                            </v-col>
                                                        </v-row>
                                                    </v-col>
                                                </v-row>
                                            </v-container>
                                        </v-card>

                                        <v-btn @click="addAnswer">Добави отговор</v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="createQuestion"
                            >
                                Запиши
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QuestionService from '@/services/question.service';
import QuestionTypeService from '@/services/question-type.service';
import { VueEditor } from "vue2-editor";

export default {
    name: 'CreateCategoryQuestion',
    data: () => ({
        question: {
            question: '',
            text: '',
            side_text: '',
            type_id: null,
            images: [],
            answers: [],
            category_id: null
        },
        questionTypes: [],
        deletedAnswers: [],
    }),
    components: {VueEditor},
    computed: {},
    watch: {},

    validations() {

        return {};
    },
    methods: {

        selectFile(file) {

            this.file = file;
        },
        addAnswer() {
            this.question.answers.push({
                text: '',
                is_correct_answer: false,
            });
        },
        deleteAnswer(index, answer) {
            
            if (answer.id) {

                this.deletedAnswers.push(answer.id);
            }
            
            this.question.answers.splice(index, 1);
        },
        addFile(pageFile) {

            console.log(pageFile);
            this.files.push(pageFile);
        },
        getQuestionTypes() {

            QuestionTypeService.get().then((res) => {

                this.questionTypes = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        getQuestion(id) {

            QuestionService.getOne(id).then((res) => {

                this.question = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        createQuestion() {

            let bodyFormData = new FormData();
            bodyFormData.set('question', this.question.question);
            bodyFormData.set('text', this.question.text);
            bodyFormData.set('side_text', this.question.side_text ?? '');
            bodyFormData.set('type_id', this.question.type_id);
            bodyFormData.set('answers', JSON.stringify(this.question.answers));

            if (this.file) {

                bodyFormData.set(`image`, this.file);
            }

            if (this.deletedAnswers.length > 0) {

                bodyFormData.set(`answers_to_delete`, JSON.stringify(this.deletedAnswers));
            }

            if (this.$route.params.question_id) {

                QuestionService.update(this.$route.params.question_id, bodyFormData).then(() => {

                    // this.getQuestion(this.$route.params.question_id);
                    this.$router.push({name: 'category-questions', params: {category_id: this.question.category_id}})
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            } else {

                bodyFormData.set('category_id', this.$route.params.category_id);

                QuestionService.create(bodyFormData).then(() => {

                    this.$router.push({
                        name: 'category-questions',
                        params: {
                            category_id: this.$route.params.category_id,
                        },
                    });
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
    },

    created() {

        this.getQuestionTypes();

        if (this.$route.params.question_id) {
            this.getQuestion(this.$route.params.question_id);
        }
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
