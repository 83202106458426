import $axios from '@/plugins/axios'

class PageService {

    get() {

        return $axios.get('/pages')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(slug) {

        return $axios.get(`/pages/${slug}`)
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    create(data) {

        return $axios.post(`/pages`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(id, data) {

        return $axios.post(`/pages/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
    
    delete(id) {

        return $axios.delete(`/pages/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new PageService();
