<template>
  <div class="main-container">
    <div class="home-image-header">
      <p class="home-hdr">Образователна платформа</p>
    </div>
    <div class="second-section">
      <v-row>
        <v-col class="second-section pt-10" id="first-second-section-container" @click="goToNewRoute('/challenges')">
          <div class="container-second-section" id="first-round">
            <div><v-icon>mdi-school-outline</v-icon></div>
          </div>
          <p class="second-section-labels">Ученик</p>
        </v-col>
        <v-col class="second-section pt-10" id="second-second-section-container" @click="goToNewRoute('/page/ucitel')">
          <div class="container-second-section" id="second-round">
           <div><v-icon>mdi-lightbulb-variant-outline</v-icon></div>
          </div>
          <p class="second-section-labels">Учител</p>
        </v-col>
        <v-col class="second-section pt-10" id="third-second-section-container"  @click="goToNewRoute('/page/roditel')">
          <div class="container-second-section" id="third-round">
            <div><v-icon>mdi-folder-arrow-left-outline</v-icon></div>
          </div>
          <p class="second-section-labels">Родител</p>
        </v-col>
        <v-col class="second-section pt-10" id="fourth-second-section-container" @click="goToContactsFooter()">
          <div class="container-second-section" id="fourth-round">
            <div><v-icon>mdi-send-outline</v-icon></div>
          </div>
          <p class="second-section-labels">Контакти</p>
        </v-col>
      </v-row>
    </div>
    <div class="third-section">
      <p>
        Образователната платформа е създадена от екип учители от СУ „Свети Климент Охридски“
        – град Варна и е част от Система за повишаване на функционалната грамотност,
        разработена по проект Грамотност.bg по програма „Учим заедно” на фондация „Заедно в час” и
        реализиран с финансовата подкрепа на Фондация „Америка за България“.      </p>
    </div>
    <div class="fourth-section">
      <v-row>
        <v-col class="fourth-cols" id="fourth-col-1"></v-col>
        <v-col class="fourth-cols" id="fourth-col-2"></v-col>
        <v-col class="fourth-cols" id="fourth-col-3"></v-col>
        <v-col class="fourth-cols" id="fourth-col-4"></v-col>
      </v-row>
    </div>

    <div class="middle-container">
      <v-row class="logo-row">

        <v-col>
          <v-img src="../../assets/amerika.png" width="100" class="logos"/>
          <p>
            Фондация „Америка за България“ е независима, неправителствена и неполитическа
            американска благотворителна фондация, която работи в партньорство с български организации
            за укрепване на частния сектор и демократичните институции в страната. Фондацията е плод на
            успеха на Българо-американския инвестиционен фонд (БАИФ), създаден през 1991 г. от
            Конгреса на САЩ с подкрепата на Американската агенция за международно развитие. За
            повече информация: www.us4bg.org
          </p>
        </v-col>

        <v-col>
          <v-img src="../../assets/zaednovchas.png" width="200" class="logos"/>

          <p>
            Фондация “Заедно в час” е една от най-активните образователни организации в
            България, която вече 11 години работи за осигуряването на равен достъп до качествено
            образование на всяко дете в България. За тази цел фондацията развива нови учители, подкрепя
            цели училищни екипи и изгражда партньорства за въвеждането на добри практики в
            образователната система. Повече за дейността и програмите на организацията:
            https://zaednovchas.bg. “Заедно в час” е създадена по инициатива и с щедрата финансова
            подкрепа на фондация „Америка за България“ и е част от международната мрежа Teach For All.
          </p>

        </v-col>

      </v-row>
    </div>

    <div class="footer-container" id="my-footer">
      <footerComponent></footerComponent>
    </div>
  </div>
</template>


<script>

const footerComponent = () => import('../../components/Common/FooterComponent.vue');
import GeneralSettingService from "@/services/general-setting.service";

export default {
  name: 'HomePage',

  components: {
    footerComponent
  },

  data: function() {
    return {

    };
  },

  created(){
    this.updateVisits();

  },

  methods: {

    updateVisits(){
      GeneralSettingService.updateVisits().then(() => {

      }).catch(() => {

      });
    },


    goToContactsFooter(){
      document.getElementById('my-footer').scrollIntoView();
    },

    goToNewRoute(link){
      this.$router.push(link);
    }
  }

}

</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Inter');

.logos{
  margin-bottom: 20px!important;
}

.logo-row{
  margin-left: 20px!important;
  margin-right: 20px!important;
}

.inside-container-for-questions{
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.6)!important;
  margin-right: 30px;
  padding: 50px;
  text-shadow: none;
  font-weight: bold;
  font-family: Inter;

}

.btn-container{
  text-align: right;
  margin-right: 30px;
  margin-bottom: 100px;
  padding-bottom: 50px;
}

.start-questions-btn{
  color: white!important;
  padding-left: 50px!important;
  padding-right: 50px!important;
  font-weight: bold;
}

.inside-fifth-container{
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 1)!important;
  margin: 50px;
  padding-top: 40px;
}

.card-title{
  text-align: left;
  text-shadow: none;
  font-family: Inter;
  font-weight: bold;
  margin-left: 120px;
  margin-bottom: 30px;
}

.fifth-container-title{
  text-align: left;
  margin-left: 120px;
  margin-top: 100px;
  margin-bottom: 90px;
  color: #5DA2D5;
  text-shadow: none;
  font-family: Inter;
  font-weight: bold;
}

.second-section{
  //padding: 40px;
  cursor: pointer;
}

.fourth-section{

  margin-bottom: 30px;

  .fourth-cols{
    width: 100%;
    height: 40px!important;
  }

  #fourth-col-1{
    background-color: #F68887;
  }

  #fourth-col-2{
    background-color: #F3D24F;
  }

  #fourth-col-3{
    background-color: #90CCF4;
  }

  #fourth-col-4{
    background-color: #5DA2D5;
  }
}

.third-section{

  p{
    text-shadow: none;
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 200px;
    padding-right: 200px;
    font-size: 1.5rem;
    font-style: italic;
    font-family: Inter;
    line-height: 2.8rem;
  }
}

.second-section-labels{
  margin-top: 20px;
  color: white;
  text-shadow: none;
  font-size: 1.7rem;
  font-family: Inter;
  font-weight: bold;
}


//@font-face {
//  font-family: ConstantiaFont;
//  src: url(../../assets/fonts/Constantia_Font.ttf);
//}

#fourth-second-section-container{
  background-color: #5DA2D5;
}

#third-second-section-container{
  background-color: #90CCF4;
}

#second-second-section-container{
  background-color: #F3D24F;
}

#first-second-section-container{
  background-color: #F68887;
}

#fourth-round{
  background-color: #F68887;
}

#third-round{
  background-color: #5DA2D5;
}

#second-round{
  background-color: #90CCF4;
}

#first-round{
  background-color: #F3D24F;
}

.container-second-section{

  background-color: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height:150px;
  margin: auto;
  box-shadow: 0px 5px 4px rgba(0, 0, 0, 0.5);


  div {

    .v-icon{
      font-size: 5.3rem!important;
      color: white;
      text-shadow: none;
    }
  }
}

.home-hdr{
  color: #5DA2D5;
  font-size: 4.6rem;
  text-align: center;
  text-shadow: none;
  font-weight: bold;
  //font-family: ConstantiaFont;
}

.home-image-header{
  background: url("../../assets/board 2.webp");
  box-shadow:inset 0 0 0 2000px rgba(237, 134, 57, 0);
  height: 900px;
  background-size: cover;
  padding-top: 105px;
  font-family: Roboto;
  text-align: left;
  padding-left: 40px;

  @media only screen and (max-width: 660px) {
    height: 560px;
    padding-left: 0px;
  };

  @media only screen and (max-width: 660px) {
    background-position: right;
    background-image: url("../../assets/board 2.webp");
  };

  @media only screen and (min-width: 1800px) {
    height: 850px;
    padding-top: 400px;
  };

  .big-hdr-text{
    color: white;
    /*width: 40%;*/
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
    font-size: 2.8rem;
    line-height: 3.4rem;
    /*font-weight: 200;*/
    font-family: "Russo One";

    @media only screen and (max-width: 660px) {
      font-size: 1.9rem;
    };
  }

  .small-hdr-text{

    @media only screen and (max-width: 660px) {
      display: none;
    };

    font-family: Rooney;
    font-weight: 600;

    color: white;
    margin-top: 20px;
    /*margin-bottom: 50px;*/
    /*width: 40%;*/
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
    font-size: 1.3rem;
    line-height: 2rem;
    /*font-weight: 200;*/
    /*font-family: "Russo One";*/
  }
}

.header-btn{
  background-image: linear-gradient(to right, rgb(0, 188, 212) 0%, rgb(10, 148, 200) 100%);
  color: white!important;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  font-family: "Russo One";
  font-weight: 200;
  /*border: 5px solid #ED8639!important;*/
  padding: 35px!important;
  font-size: 1rem!important;
  border-radius: 10px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 1);

  @media only screen and (max-width: 660px) {
    margin-bottom: 40px;
  };
}

.hdr-btn-courses{

  @media only screen and (max-width: 660px) {
    margin-top: 210px;
    margin-bottom: 25px!important;

  };

}

.about-us-container{
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
  padding-bottom: 40px;
}

.about-us-container-first-col{
  color: white;

  p{
    line-height: 2.2rem;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 1);
    font-family: Rooney;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: left;

    @media only screen and (max-width: 860px) {
      margin-left: 10px;
      margin-right: 10px;
    };
  }
}

.about-us-title{
  margin-top: 20px;
  margin-bottom: 30px;
  font-size: 2.4rem!important;
}

.about-us-btn{
  margin-top: 20px;
}

.img-about {
  margin-top: 40px;
  margin-left: 40px;

  @media only screen and (max-width: 1000px) {
    margin-left: 0px;
  };

  @media only screen and (max-width: 860px) {
    margin-top: 3px;
  };
}

.header-btn-events{

  @media only screen and (max-width: 660px) {
    margin-left: 0px;
    margin-top: 0px;
    width: 95vw!important;
  };

  margin-left: 25px;
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
}

.btn-icon-about-us{
  margin-left: 10px;
}

.btn-icon-hdr{
  margin-right: 10px;
}

.courses-carousel-container {
  background-image: linear-gradient(to left, rgb(248,248,248) 0%, rgb(255,255,255) 100%)!important;
}

.all-course-btn{

  margin-top: 15px;
  margin-bottom: 25px;
  font-weight: 200;
  color: white!important;
  background-image: linear-gradient(to right, rgb(0, 188, 212) 0%, rgb(10, 148, 200) 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 1);
}

.lecturers-carousel-container{
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
}

.faq-container{
  padding: 30px;
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
}

.info-container{
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
  color: white!important;
  text-shadow: 1px 1px 4px rgba(0, 0, 0, 1);
  font-size: 1.8rem;
  padding-top: 40px;
  padding-bottom: 40px;

  @media only screen and (max-width: 860px) {
    padding: 20px!important;
  };

  .main-row-info{
    margin-top: 30px;
  }
}

.info-logo-col{

  img{
    width: 110px;
  }

  @media only screen and (max-width: 860px) {
    /*display: none;*/
  };
}

.footer-container{
  background-image: linear-gradient(to right, rgb(237, 134, 57) 0%, rgb(247, 159, 11) 100%)!important;
  color: white!important;
}

.hdr-text-cont {
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 1);
  width: 40%;
  padding: 20px;
  border-radius: 20px;
  padding-bottom: 5px;
  background-color: rgba(0, 0, 0, 0.4);
  margin-bottom: 40px;

  @media only screen and (max-width: 660px) {
    width: 80%;
    display: none;
    background-color: rgba(0, 0, 0, 0.6);
    margin-top: 215px;
    text-align:center;
    margin-left: auto;
    margin-right: auto;
  }
}

.mobile-div-btns-hdr{
  @media only screen and (max-width: 660px) {
    text-align: center;
  }
}

.dash-span{
  @media only screen and (max-width: 660px) {
    display: none;
  }
}

.desktop-word{
  @media only screen and (max-width: 820px) {
    display: none;
  }
}

.mobile-word{
  @media only screen and (min-width: 820px) {
    display: none;
  }
}


</style>
