<template>
    <div class="content-header">
        <h1 class="md-headline">{{headline}}</h1>
        <slot/>
    </div>

</template>

<script>
    export default {
        name: 'HeaderComponent',
        props: {
            headline: {
                type: String
            }
        }
    }
</script>

<style lang="scss" scoped>
    .content-header {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin: 3.2rem 0 2.4rem;

        @media only screen and (max-width: 600px) {
            flex-direction: column;
            align-items: flex-start;
        };

        .md-headline {
            margin: 0;
            font-size: 3.4rem;
            font-weight: 600;
          
            @media only screen and (max-width: 600px) {
                margin-bottom: 1.6rem;
                line-height: 1.2;
                text-align: center;
                margin-left: auto;
                margin-right: auto;
            };
        }

        &--medium .md-headline {
            font-size: 2.4rem;
        }

        .md-button {
            padding: 0 15px;
            margin: 0;

            @media only screen and (max-width: 600px) {
                width: 100%;
            };
        }
    }

    /*
    * In case you need more than one button in page header,
    * you'll need to add actions wrapper for buttons */
    .content-header__actions {

        display: flex;
        align-items: flex-start;

        @media only screen and (max-width: 600px) {
            width: 100%;
            display: block;
        };

        .md-button:first-child {
            margin-right: 2.4rem;

            @media only screen and (max-width: 600px) {
                margin: 0 0 1.6rem;
            };

        }
    }
</style>