<template>
    <div class="main-container">

<!--        <v-container fluid style="margin-bottom: 20px;">-->
<!--            <v-row>-->
<!--                <v-col>-->
<!--                    <div v-for="(challenge, index) in challenges" :key="index + '_challenge'">-->
<!--                        <v-btn-->
<!--                            @click="openSubsectionsPage(challenge)">-->
<!--                            {{ challenge.name }}-->
<!--                        </v-btn>-->
<!--                    </div>-->
<!--                </v-col>-->
<!--            </v-row>-->
<!--        </v-container>-->
      <iframe width="960" height="640" data-original-width="4659" data-original-height="3106"  src="https://www.thinglink.com/card/1585618889574711299" type="text/html" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen scrolling="no"></iframe>
      <script type="rocketlazyloadscript" data-minify="1" async src="https://brimki.net/wp-content/cache/min/1/jse/responsive.js?ver=1650021366"></script>
      <div class="footer-container">
            <footerComponent></footerComponent>
        </div>
    </div>
</template>

<script>

import ChallengeService from '@/services/challenge.service';

const footerComponent = () => import('../../components/Common/FooterComponent.vue');

export default {
    name: 'ChallengesPage',

    components: {
        footerComponent,
    },

    data: function() {
        return {
            challenges: [],
        };
    },

    methods: {

        openSubsectionsPage(challenge) {
            this.$router.push({
                name: 'challenge-subsections',
                params: {
                    id: challenge.id,
                },
            });
        },
        getChallenges() {
            ChallengeService.get().then((res) => {
                this.challenges = res;
            });
        },
        goToNewRoute(link) {
            this.$router.push(link);
        },
    },

    created() {
        this.getChallenges();
    },

};
</script>

<style lang="scss" scoped>

  .main-container{
    text-align: center!important;
  }

</style>
