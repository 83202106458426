import VueCookies from 'vue-cookies';
import AuthService from '@/services/auth.service';

const setUpCookie = (token) => {
	
	VueCookies.set('current_user', token, '1Y', null, null, false, 'Lax');
};

const state = () => ({
	user: null,
});

const getters = {
	
	isAuthenticated: () => () => {
		
		let cookie = VueCookies.get('current_user');
		
		return cookie != null;
	},
		
	getCurrentUser(state) {
		return state.user;
	},
	
	currentUserRole(state) {
		return state.user ? state.user.role_id: null;
	},
};

const mutations = {
	
	setUser(state, user) {
		state.user = user;
	},
	
	logout(state) {
		state.user = null;
	},
	
	signOut(state) {
		state.user = null;
		VueCookies.remove('current_user');
		VueCookies.remove('user_data');
	},	
};

const actions = {
		
	signOut(vuexContext) {
		const oldToken = VueCookies.get('real_user');
		
		if (oldToken) {
			VueCookies.remove('real_user');
			vuexContext.commit('toggleSudoMode', false);
			return vuexContext.dispatch('getUserFromToken', oldToken);
		} else {
			vuexContext.commit('signOut');
			return Promise.resolve(null);
		}
	},
	
	
	login(vuexContext, data) {
		
		let payload = {
			email: data.email,
			password: data.password,
		};
		
		return AuthService.login(payload).then(data => {
			
			setUpCookie(data.auth_token, data.user);
			vuexContext.commit('setUser', data.user);
			
			return data;
		}).catch((error) => {
			
			let errorMessage = 'Failed. Check your credentials, please.';
			
			if (403 === error.response.status){
				
				errorMessage = error.response.data;
			}
			
			vuexContext.dispatch('showSnackbarMessage', {
				message: errorMessage,
				duration: 8000,
				mode: 'fail',
			}, {root: true});
			
			return new Promise((resolve, reject) => {
				return reject(error);
			});
		});
	},
		
};

export default {
	namespaced: true,
	state,
	getters,
	actions,
	mutations,
};
