import axios from 'axios';
import VueCookies from 'vue-cookies';
import store from '../store';
import router from '../router';

let $axios = axios.create({
	baseURL: process.env.VUE_APP_API_BASE_URL,
	// withCredentials: true,
	headers: {
		// 'Access-Control-Allow-Origin': '*',
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

$axios.interceptors.request.use(
		(config) => {
			
			let cookie = VueCookies.get('current_user');
			
			if (cookie) {
				
				config.headers.Authorization = `Bearer ${VueCookies.get(
						'current_user')}`;
			}
			
			// config.headers.Cookie = `current_user=${Cookie.get('current_user')}`;
			return config;
		},
		(error) => {
			return Promise.reject(error);
		},
);

$axios.interceptors.response.use(
		(response) => {
			return response;
		},
		(error) => {
			
			let errorMessage;
			
			switch (error.response.status){
				case 404: errorMessage = error.response.data; break;
				case 422: errorMessage = `Validation error`; break;
				default: errorMessage = error.response.data; break;
			}
			
			store.dispatch('showSnackbarMessage', {
				message: errorMessage,
				duration: 4000,
				mode: 'fail',
			});
			
			if (401 === error.response.status) {
				
				VueCookies.remove('current_user');
				
				if (router.currentRoute.path !== '/login') {
					
					router.push('/login');
				}
				// location.replace('/login');
			}
			
			return Promise.reject(error);
		},
);

export default $axios;
