<template>
    <v-container>
        <v-row>
            <v-col>
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                    > Създай страница
                    </v-btn>
                </template>

                <v-form
                    ref="form"
                    lazy-validation
                >
                    <v-card>
                        <v-card-title>
                            <span class="text-h5">Въпроси</span>
                        </v-card-title>
                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                        md="6"
                                    >
                                        <v-text-field
                                            label="Въпрос*"
                                            required
                                            v-model="questionnaire_question.question_text"
                                        ></v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                      <span>Отговор*</span>
                                      <vue-editor
                                        v-model="questionnaire_question.answer_text"></vue-editor>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col
                                        cols="12"
                                        sm="12"
                                        md="12"
                                    >
                                        <v-color-picker
                                            dot-size="25"
                                            swatches-max-height="200"
                                            v-model="questionnaire_question.color"
                                        ></v-color-picker>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="blue darken-1"
                                text
                                @click="signButtonAction"
                            >
                                Запиши
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QuestionnaireService from '@/services/questionnaire.service';
import { VueEditor } from "vue2-editor";

export default {
    name: 'QuestionnaireQuestion',
    data: () => ({
        questionnaire_question:{
            question_text: null,
            answer_text: null,
            color: '',
        },
    }),
    components: {VueEditor},
    computed: {},

    watch: {},

    validations() {

        return {
        };
    },
    methods: {

        addFile(pageFile) {

            console.log(pageFile);
            this.files.push(pageFile);
        },
        getQuestion(id){

            QuestionnaireService.getOne(id).then((data) => {

                this.questionnaire_question = data;

                // this.$router.push('/admin/questionnaire');
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },

        signButtonAction(){

          if(this.$route.params.id){

            this.editQuestion();

          } else{

            this.createQuestion();

          }

        },

        editQuestion(){

          console.log(this.questionnaire_question);

          QuestionnaireService.update(this.questionnaire_question.id, this.questionnaire_question).then(() => {

            this.$router.push('/admin/questionnaire');
            // this.loadingSpinner = false;
          }).catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail',
            });
            // this.loadingSpinner = false;
          });
        },
        createQuestion() {

            QuestionnaireService.create(this.questionnaire_question).then(() => {

                this.$router.push('/admin/questionnaire');
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
    },

    created() {

        if (this.$route.params.id){
            this.getQuestion(this.$route.params.id)
        }
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
