<template>
    <v-container>
        <v-row>
            <v-col>
                <v-btn
                    color="white"
                    @click="openCreateDialog"
                > Създай предизвикателство
                </v-btn>
                <ChallengeFormModal @challengeSaved="onChallengeSaved"
                                    :challenge-prop="challengeToEdit"
                                    :show-dialog-prop.sync="showDialog"
                                    :key="dialogKey"/>
            </v-col>
            <!--            <v-btn class="ma-4" @click="$router.push({name:'create-page'})">-->
            <!--                Създай предизвикателство-->
            <!--            </v-btn>-->
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Име
                            </th>
                            <th class="text-left">
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in challenges"
                            :key="index">
                            <td>{{ item.name }}</td>
                            <td>
                                <v-btn
                                    @click="$router.push({name: 'admin-challenge-subsections', params: {id: item.id}})">
                                    Подраздели
                                </v-btn>
                            </td>
                            <td>
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="openEditDialog(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    medium
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import ChallengeService from '@/services/challenge.service';
import ChallengeFormModal from '@/components/Admin/Challenges/ChallengeFormModal';

export default {
    name: 'AdminChallenges',
    data: () => ({
        challenges: [],
        challengeToEdit: null,
        showDialog: false,
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {ChallengeFormModal},
    computed: {},
    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        onChallengeSaved() {
            this.loadChallenges();
        },
        loadChallenges() {

            ChallengeService.get().then((res) => {

                this.challenges = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        openCreateDialog() {

            this.challengeToEdit = null;
            this.dialogKey++;
            this.showDialog = true;
        },
        openEditDialog(challenge) {

            this.challengeToEdit = challenge;
            this.dialogKey++;
            this.showDialog = true;
        },
        deleteItem(item) {

            if (confirm('Сигурни ли сте, че искате да изтриете това предизвикателство?') === true) {
                ChallengeService.delete(item.id).then(() => {

                    this.loadChallenges();
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
    },

    created() {

        this.loadChallenges();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
