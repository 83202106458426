<template>
    <v-container>
        <v-row>
            <v-btn class="ma-4" @click="$router.push({name:'create-questionnaire-question'})">
                Добави въпрос
            </v-btn>
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Въпрос
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr
                            v-for="(item, index) in questions"
                            :key="index"
                        >
                            <td>{{ item.question_text }}</td>
                            <td>
                                <v-icon
                                    medium
                                    class="mr-2"
                                    @click="editPage(item)"
                                >
                                    mdi-pencil
                                </v-icon>
                                <v-icon
                                    medium
                                    @click="deleteItem(item)"
                                >
                                    mdi-delete
                                </v-icon>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import QuestionnaireService from '@/services/questionnaire.service';

export default {
    name: 'AdminQuestions',
    data: () => ({
        questions: [],
        page: null,
        loadingSpinner: true,
        dialogKey: 0,
    }),
    components: {},
    computed: {},

    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        loadQuestions() {

            QuestionnaireService.get().then((res) => {

                this.questions = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        editPage(item) {

            this.$router.push({name:'edit-questionnaire-question', params: {id: item.id}});
        },
        deleteItem(item) {
            
            if (confirm("Сигурни ли сте, че искате да изтриете този въпрос?") === true) {
                QuestionnaireService.delete(item.id).then(() => {

                    this.loadQuestions()
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            } 
        },
        openAddPageModal() {

            this.dialogKey++;
            this.page = null;
            // this.genres = [];
            this.showPageFormModal = true;
        },
    },

    created() {

        this.loadQuestions();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}
</style>
