import $axios from '@/plugins/axios'

class FileService {

    getOne(id) {

        return $axios.get(`/files/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }
}

export default new FileService();
