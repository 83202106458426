<template>
    <v-container>
        <v-row>
            <v-col>
                <p v-if="this.category">Въпроси на категория {{ this.category.name }}</p>
            </v-col>
            <v-col>
                <v-btn
                    color="white"
                    @click="openCreateQeustionPage"
                > Създай въпрос
                </v-btn>
                <!--                <QuestionFormModal @questionSaved="onQuestionSaved"-->
                <!--                                    :question-prop="questionToEdit"-->
                <!--                                    :show-dialog-prop.sync="showDialog"-->
                <!--                                    :key="dialogKey"/>-->
            </v-col>
            <!--            <v-btn class="ma-4" @click="$router.push({name:'create-page'})">-->
            <!--                Създай предизвикателство-->
            <!--            </v-btn>-->
        </v-row>
        <v-row>
            <v-col>
                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Въпрос
                            </th>
                            <th class="text-left">
                                Действия
                            </th>
                        </tr>
                        </thead>

                        <draggable
                            :list="questions"
                            class="list-group"
                            ghost-class="ghost"
                            tag="tbody"
                            @end="reorder"
                        >

                            <tr
                                v-for="(item, index) in questions"
                                :key="index"
                                class="list-group-item"
                            >
                                <td v-html="item.question">{{item.question}}</td>
                                <td>
                                    <v-icon
                                        medium
                                        class="mr-2"
                                        @click="openEditPage(item)"
                                    >
                                        mdi-pencil
                                    </v-icon>
                                    <v-icon
                                        medium
                                        @click="deleteItem(item)"
                                    >
                                        mdi-delete
                                    </v-icon>
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </v-simple-table>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import draggable from 'vuedraggable';
import CategoryService from '@/services/category.service';
import QuestionService from '@/services/question.service';
// import CategoryFormModal from '@/components/Admin/Challenges/CategoryFormModal';

export default {
    name: 'AdminQuestions',
    data: () => ({
        category: null,
        questions: [],
        questionToEdit: null,
        showDialog: false,
        loadingSpinner: true,
        dialogKey: 0,
        list: [
            {name: 'John', id: 0},
            {name: 'Joao', id: 1},
            {name: 'Jean', id: 2},
        ],
    }),
    components: {
        // CategoryFormModal
        draggable,
    },
    computed: {},
    watch: {},

    validations() {

        let settings = {};

        return {
            settings,
        };
    },
    methods: {

        openCreateQeustionPage() {
            this.$router.push({
                name: 'create-category-question',
                params: {
                    category_id: this.$route.params.category_id,
                },
            });
        },
        onCategorySaved() {
            this.loadCategories();
        },
        loadCategory() {

            CategoryService.getOne(this.$route.params.category_id).then((res) => {

                this.category = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        loadQuestions() {

            CategoryService.getQuestions(this.$route.params.category_id).then((res) => {

                this.questions = res;
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
        openEditPage(item) {

            this.$router.push({
                name: 'update-category-question',
                params: {
                    question_id: item.id,
                },
            });
        },
        deleteItem(item) {

            if (confirm('Сигурни ли сте, че искате да изтриете този въпрос?') === true) {
                QuestionService.delete(item.id).then(() => {

                    this.loadQuestions();
                    // this.loadingSpinner = false;
                }).catch(() => {

                    this.$store.dispatch('showSnackbarMessage', {
                        message: 'Failed. Please, try again.',
                        duration: 8000,
                        mode: 'fail',
                    });
                    // this.loadingSpinner = false;
                });
            }
        },
        reorder() {

            QuestionService.reorder({questions: this.questions}).then(() => {

                this.loadQuestions();
                // this.loadingSpinner = false;
            }).catch(() => {

                this.$store.dispatch('showSnackbarMessage', {
                    message: 'Failed. Please, try again.',
                    duration: 8000,
                    mode: 'fail',
                });
                // this.loadingSpinner = false;
            });
        },
    },

    created() {

        this.loadCategory();
        this.loadQuestions();
    },
};
</script>

<style lang="scss" scoped>

.content-header__actions {
    width: 38%;
}

@media screen and (min-width: 640px) {
    .input-field.input-field--filter {
        margin-right: 2.4rem;
    }
}

.content-header.content-header__stores {
    margin-bottom: 40px;
}

.input-field.input-field__stores-filter {
    padding-bottom: 0;
}

.button {
    margin-top: 35px;
}

.flip-list-move {
    -webkit-transition: -webkit-transform 0.5s;
    transition: -webkit-transform 0.5s;
    transition: transform 0.5s;
    transition: transform 0.5s, -webkit-transform 0.5s;
}

.no-move {
    -webkit-transition: -webkit-transform 0s;
    transition: -webkit-transform 0s;
    transition: transform 0s;
    transition: transform 0s, -webkit-transform 0s;
}

.ghost {
    opacity: 0.5;
    background: #c8ebfb;
}

.list-group {
    min-height: 20px;
}

.list-group-item {
    cursor: move;
}

.list-group-item i {
    cursor: pointer;
}

</style>
