<template>
    <div class="page-container">
        <div>
            <v-app-bar dense dark class="app-bar-main">
                <v-spacer></v-spacer>

                <v-btn icon @click="logout">
                    <v-icon>mdi-logout</v-icon>
                </v-btn>
            </v-app-bar>
            <v-navigation-drawer
                app
                permanent                
                bottom
                dark
                class="nav-drawer">
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6">
                            <v-lazy transition="fade-transition">
                                <img alt="my-logo" src="@/assets/logo.webp" width="200"/>
                            </v-lazy>
                        </v-list-item-title>
<!--                        <v-list-item-subtitle>-->
<!--                            subtext-->
<!--                        </v-list-item-subtitle>-->
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>
                <v-list nav dense style="margin-top: 50px;">
                    <v-list-item-group
                        active-class="deep-purple--text text--accent-4">
                        <v-list-item v-for="(btn, index) in btns"
                                     :key="index + '_btns-menu-mobile'"
                                     class="mobile-menu-containers"
                                     @click.prevent="goToNewRoute(btn.link)">
                            <v-list-item-title class="mobile-btn-text-white">{{ btn.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>


              <p class="visits">ПОСЕЩЕНИЯ: {{ visits }}</p>
            </v-navigation-drawer>

            <v-container>
                <router-view></router-view>
            </v-container>
        </div>
    </div>
</template>

<script>

import GeneralSettingService from "@/services/general-setting.service";

export default {
    name: 'TheLayoutAdminWrapper',

    data: () => ({
        drawer: true,
        btns: [
            {
                text: 'Настройки',
                link: 'general-settings',
            },
            {
                text: 'Страници',
                link: 'pages',
            },
            {
                text: 'Страница въпроси',
                link: 'admin-questionnaire',
            },
            {
                text: 'Предизвикателства',
                link: 'admin-challenges',
            },
        ],
      visits: 0,
    }),

    methods: {

        getVisits(){
          GeneralSettingService.getVisits().then((res) => {

            this.visits = res.visits;

          }).catch(() => {

            this.$store.dispatch('showSnackbarMessage', {
              message: 'Failed. Please, try again.',
              duration: 8000,
              mode: 'fail',
            });
          });
        },

        goToNewRoute(link) {
            this.$router.push({name: link});
        },
        logout() {

            this.$store.dispatch('auth/signOut').then(() => {

                this.$router.replace('/login');
            });
        }
    },

  created(){

      this.getVisits();
  }

};
</script>

<style lang="scss" scoped>

@import url('https://fonts.googleapis.com/css?family=Inter');


.visits{
  color: white;
  margin-left: 30px;
  position: absolute;
  bottom: 0;
}

#inspire {
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0);
}

.app-bar-main {
    background-color: #5DA2D5 !important;

    height: 80px !important;
    padding-top: 15px !important;
}

</style>

