export default function guest({next, store, nextMiddleware}) {

    console.log("guest middleware");

    let isAuthenticated = store.getters['auth/isAuthenticated']();

    console.log('isAuthenticated')
    console.log(isAuthenticated)

    if (isAuthenticated) {

        let path = '/admin';

        return next({path: path});
    }

    return nextMiddleware();
}
