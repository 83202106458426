<template>
    <div class="component-wrap">
        <pulse-loader v-if="type === 'pulse'" :loading="loading" :color="color" :size="size"></pulse-loader>
        <grid-loader v-if="type === 'grid'" :loading="loading" :color="color" :size="size"></grid-loader>
        <clip-loader v-if="type === 'clip'" :loading="loading" :color="color" :size="size"></clip-loader>
        <rise-loader v-if="type === 'rise'" :loading="loading" :color="color" :size="size"></rise-loader>
        <beat-loader v-if="type === 'beat'" :loading="loading" :color="color" :size="size"></beat-loader>
        <sync-loader v-if="type === 'sync'" :loading="loading" :color="color" :size="size"></sync-loader>
        <rotate-loader v-if="type === 'rotate'" :loading="loading" :color="color" :size="size"></rotate-loader>
        <fade-loader v-if="type === 'fade'" :loading="loading" :color="color" :height="height"
                     :width="width"></fade-loader>
        <pacman-loader v-if="type === 'pacman'" :loading="loading" :color="color" :size="size"></pacman-loader>
        <square-loader v-if="type === 'square'" :loading="loading" :color="color" :size="size"></square-loader>
        <scale-loader v-if="type === 'scale'" :loading="loading" :color="color" :height="height"
                      :width="width"></scale-loader>
        <skew-loader v-if="type === 'skew'" :loading="loading" :color="color" :size="size"></skew-loader>
        <moon-loader v-if="type === 'moon'" :loading="loading" :color="color" :size="size"></moon-loader>
        <ring-loader v-if="type === 'ring'" :loading="loading" :color="color" :size="size"></ring-loader>
        <bounce-loader v-if="type === 'bounce'" :loading="loading" :color="color" :size="size"></bounce-loader>
        <dot-loader v-if="type === 'dot'" :loading="loading" :color="color" :size="size"></dot-loader>
    </div>
</template>

<script>
import {
    PulseLoader,
    GridLoader,
    ClipLoader,
    RiseLoader,
    BeatLoader,
    SyncLoader,
    RotateLoader,
    FadeLoader,
    PacmanLoader,
    SquareLoader,
    ScaleLoader,
    SkewLoader,
    MoonLoader,
    RingLoader,
    BounceLoader,
    // DorLoader,
} from 'vue-spinner/dist/vue-spinner.min.js';

export default {
    name: 'SpinnerComponent',
    props: {
        type: {
            type: String,
            default: 'pulse',
        },
        loading: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: '#CD0A2F',
        },
        size: {
            type: String,
            default: '10px',
        },
    },
    components: {
        PulseLoader,
        GridLoader,
        ClipLoader,
        RiseLoader,
        BeatLoader,
        SyncLoader,
        RotateLoader,
        FadeLoader,
        PacmanLoader,
        SquareLoader,
        ScaleLoader,
        SkewLoader,
        MoonLoader,
        RingLoader,
        BounceLoader,
        // DorLoader,
    },
};
</script>
<style lang="scss" scoped>
.component-wrap {
    width: 100%;
    padding: 6rem 0;
    text-align: center;
    //$color-white
    /*background-color: rgba(#FFF, .6);*/
}

.floating-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
}
</style>
