import $axios from '@/plugins/axios'

class QuestionnaireService {

    get() {

        return $axios.get('/challenges')
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getOne(id) {

        return $axios.get(`/challenges/${id}`)
            .then(res => {

                
                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    getSubsections(id) {

        return $axios.get(`/challenges/${id}/subsections`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    create(data) {

        return $axios.post(`/challenges`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    update(id, data) {

        return $axios.put(`/challenges/${id}`, data)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

    delete(id) {

        return $axios.delete(`/challenges/${id}`)
            .then(res => {

                return res.data;
            })
            .catch((error) => {

                console.log(error)
                throw error;
            })
    }

}

export default new QuestionnaireService();
